import { useSearchParams } from "react-router-dom";
import MainLayout from "../../components/Layouts/main";
import { VscExport } from "react-icons/vsc";
import { TableExtrato } from "./components/table-extrato";
import { useEffect, useState } from "react";
import ListServiceResult from "../../interfaces/list-service-result";
import ExtratoListItemModel from "../../interfaces/models/extrato-list-item.model";
import api from "../../services/api-client";
import apiErrorHandler from "../../services/api-error-handler";
import { NoData } from "../../components/NoData";

export default function Extrato() {
  const [loading, setLoading] = useState<boolean>(true);
  const [transacoes, setTransacoes] =
    useState<ListServiceResult<ExtratoListItemModel> | null>(null);
  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();

  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  async function obterExtrato(): Promise<void> {
    api
      .get<ListServiceResult<ExtratoListItemModel>>(
        `/extrato?pagina=${pagina}&paginaTamanho=${paginaTamanho}`,
      )
      .then(({ data: result }) => {
        setTransacoes(result);
        setNumeroPaginas(result.pages);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    obterExtrato();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, paginaTamanho]);

  return (
    <MainLayout>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <p className="h1 m-0">Extrato</p>
        <button
          type="button"
          className="btn border d-flex align-items-center justify-content-center"
        >
          <span className="semibold-16">Exportar dados</span>
          <VscExport className="mx-2" />
        </button>
      </div>

      {loading && <div className="text-center">Carregando...</div>}

      {!loading && transacoes && transacoes.data?.length > 0 && (
        <TableExtrato
          numeroPaginas={numeroPaginas}
          transacoes={transacoes.data}
        />
      )}

      {!loading && (!transacoes || transacoes?.data?.length === 0) && (
        <NoData
          title="Você ainda não possui transações"
          description="Não foi encontrado nenhum registro no seu histórico. Assim que houver movimentações, estará disponível seus lançamentos bem aqui."
        />
      )}
    </MainLayout>
  );
}
