import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import ReactInputMask from "react-input-mask";
import { Link } from "react-router-dom";
import ChavePixTipo from "../../../../enums/chave-pix-tipo";
import PixConsultasPostArgs from "../../../../interfaces/args/pix-consultas-post.args";
import PixEnviosPostArgs from "../../../../interfaces/args/pix-envios-post.args";
import PixConsultaModel from "../../../../interfaces/models/pix-consulta.model";
import ServiceResult from "../../../../interfaces/service-result";
import api from "../../../../services/api-client";
import apiErrorHandler from "../../../../services/api-error-handler";
import { useEnvioPIXStore } from "../../../../stores/enviar-pix-store";
import somenteNumeros from "../../../../utils/somente-numeros";

export const DadosPessoais = () => {
  const { args, setArgs, setStep, setConsulta } = useEnvioPIXStore();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setFocus,
  } = useForm<PixConsultasPostArgs>({
    values: {
      tipo: args.chavePixTipo || ChavePixTipo.CPF,
      chave: args.chavePix,
    },
  });

  const tipo = watch("tipo");

  function getMask(): string | (string | RegExp)[] {
    switch (tipo) {
      case ChavePixTipo.CPF:
        return "999.999.999-99";

      case ChavePixTipo.CNPJ:
        return "99.999.999/9999-99";

      case ChavePixTipo.Telefone:
        return "(99) 99999-9999";

      default:
        return "";
    }
  }

  async function onSubmit(data: PixConsultasPostArgs): Promise<void> {
    // setLoading(true);

    let body: PixConsultasPostArgs = {} as PixConsultasPostArgs;
    body.tipo = data.tipo;

    if (
      data.tipo === ChavePixTipo.CPF ||
      data.tipo === ChavePixTipo.CNPJ ||
      data.tipo === ChavePixTipo.Telefone
    ) {
      body.chave = somenteNumeros(data.chave as string);
    } else {
      body.chave = data.chave as string;
    }

    // api
    //   .post<ServiceResult<PixConsultaModel>>("/pix/consultas", body)
    //   .then(({ data: result }) => {
    //     setConsulta(result.data as PixConsultaModel);
    //
    //     setArgs({
    //       ...args,
    //       chavePix: body.chave,
    //       chavePixTipo: body.tipo,
    //     } as PixEnviosPostArgs);
    //
    //     setStep(2);
    //   })
    //   .catch((err) => apiErrorHandler(err))
    //   .finally(() => setLoading(false));

    // setConsulta(result.data as PixConsultaModel);

    setArgs({
      ...args,
      chavePix: body.chave,
      chavePixTipo: body.tipo,
    } as PixEnviosPostArgs);

    setStep(2);
  }

  useEffect(() => {
    setValue("chave", "");
    setFocus("chave");
  }, [tipo, setValue, setFocus]);

  return (
    <>
      <p className="h2 mx-3 mx-md-5 my-4">Para quem você vai transferir?</p>

      {/* FORM */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row px-3 px-md-5">
          <div className="col-lg-6 mb-3">
            <label htmlFor="recipient-name" className="col-form-label">
              Selecione o tipo de chave:
            </label>
            <select
              {...register("tipo", {
                required: "Tipo de chave é obrigatório",
              })}
              className="form-select"
            >
              {Object.values(ChavePixTipo).map((tipo) => (
                <option key={tipo} value={tipo}>
                  {tipo === ChavePixTipo.EVP ? "Chave aleatória" : tipo}
                </option>
              ))}
            </select>
          </div>

          <div className="col-lg-12 mb-3">
            <label htmlFor="chave" className="form-label">
              Chave PIX
            </label>

            {tipo === ChavePixTipo.Email || tipo === ChavePixTipo.EVP ? (
              <input
                type="text"
                {...register("chave", {
                  required: "Chave é obrigatória",
                })}
                className={clsx(
                  "form-control",
                  errors.chave && "border-danger is-invalid",
                )}
                placeholder="Insira a chave"
              />
            ) : (
              <ReactInputMask
                type="text"
                mask={getMask()}
                maskChar="X"
                {...register("chave", {
                  required: "Chave é obrigatória",
                })}
                className={clsx(
                  "form-control",
                  errors.chave && "border-danger is-invalid",
                )}
                placeholder="Insira a chave"
              />
            )}

            {errors.chave && (
              <p className="text-danger m-0">
                {errors.chave.message?.toString()}
              </p>
            )}
          </div>
        </div>

        {/* FORM BUTTONS */}
        <div className="p-3 p-md-4 d-flex flex-md-row justify-content-between justify-content-md-end align-items-center">
          <Link
            to="/pix"
            className="d-flex align-items-center text-decoration-none cursor-pointer m-0 me-md-4"
          >
            <FaChevronLeft className="text-black" />
            <span className="text-uppercase fw-bold text-black ms-2">
              Voltar
            </span>
          </Link>
          <button type="submit" disabled={loading} className="btn btn-primary">
            {loading ? "Carregando..." : "Avançar"}
          </button>
        </div>
      </form>
    </>
  );
};
