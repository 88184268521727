import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import CleanLayout from "./Layouts/clean";
import { Info } from "./TextCards/info";

interface Props {
  backAction: () => void;
  handleSubmit: (code: string) => void;
}

// current index should be a global variable 'cause it's used in 2 different events: onChange and onKeyDown
// it's not the best practice, but it's the only way to make it work 'cause react sucks
let currentInputIndex: number = 0;

export default function ConfirmSMS({ backAction, handleSubmit }: Props) {
  const [inputs, setInputs] = useState<string[]>(Array(6).fill(""));
  const [activeInputIndex, setActiveInputIndex] = useState<number>(0); // state to control the current active input

  const inputRef = useRef<HTMLInputElement>(null);

  function handleOnChange({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = target;
    const newInputs: string[] = [...inputs];

    newInputs[currentInputIndex] = value.substring(value.length - 1); // only get the last character

    if (!value) setActiveInputIndex(currentInputIndex - 1);
    else setActiveInputIndex(currentInputIndex + 1);

    setInputs(newInputs);
  }

  function handleOnKeyDown(
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ): void {
    currentInputIndex = index;

    if (key === "Backspace") {
      setActiveInputIndex(currentInputIndex - 1);
    }
  }

  useEffect(() => {
    inputRef.current?.focus(); // focus the first input when the page loads
  }, [activeInputIndex]);

  return (
    <CleanLayout backAction={backAction}>
      <div className="border rounded p-3 p-md-4 p-lg-5 m-2">
        <p className="h2 text-center mb-4">
          Confirme o código recebido
        </p>

        <div className="d-flex justify-content-center">
          <p className="regular-16 text-center mb-4" style={{ maxWidth: 500 }}>
            Um código de 6 dígitos foi enviado para o seu celular e e-mail.
            Digite-o abaixo para confirmar sua identidade.
          </p>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(inputs.join(""));
          }}
          className="d-flex flex-column align-items-center"
        >
          <div className="w-100 d-flex align-items-center justify-content-evenly">
            {inputs.map((_, i) => (
              <input
                ref={i === activeInputIndex ? inputRef : null}
                key={i}
                type="number"
                className={clsx("form-control text-center", i !== 0 && "ms-2")}
                style={{ width: "100%", maxWidth: "50px" }}
                value={inputs[i]}
                onChange={handleOnChange}
                onKeyDown={(e) => handleOnKeyDown(e, i)}
              />
            ))}
          </div>

          <div className="my-4">
            <Info text="Digite o código de 6 dígitos enviado para o seu celular" />
          </div>

          <button type="submit" className="w-100 btn btn-primary">
            Confirmar
          </button>
        </form>
      </div>
    </CleanLayout>
  );
}
