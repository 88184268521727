export const IconBoletos = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      data-name="Grupo 10871"
      viewBox="0 0 24 24"
    >
      <g data-name="Grupo 10870" transform="translate(4.5 3)">
        <rect
          width="15"
          height="18"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          data-name="Retângulo 3906"
          rx="2"
        ></rect>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M0 0L2 0"
          data-name="Linha 77"
          transform="translate(9.5 4.5)"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M0 0L2 0"
          data-name="Linha 78"
          transform="translate(9.5 7.5)"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M0 0L8 0"
          data-name="Linha 79"
          transform="translate(3.5 12)"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M0 0L8 0"
          data-name="Linha 80"
          transform="translate(3.5 15)"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M0 0.75L0 0"
          data-name="Linha 81"
          transform="translate(5 3)"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M0 0L0 0.747"
          data-name="Linha 82"
          transform="translate(5 8.253)"
        ></path>
        <g data-name="Grupo 10869" transform="translate(3.5 3.75)">
          <path
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M.198 3.964a1.084 1.084 0 00.927.539h.822a1.053 1.053 0 00.256-2.075L.797 2.075A1.053 1.053 0 011.053 0h.822A1.08 1.08 0 012.8.536"
            data-name="Caminho 3203"
          ></path>
        </g>
      </g>
      <path fill="none" d="M0 0H24V24H0z" data-name="Retângulo 3907"></path>
    </svg>
  );
}
