import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import CleanLayout from "../../../../components/Layouts/clean";
import { Info } from "../../../../components/TextCards/info";
import { useCobrarPIXStore } from "../../../../stores/cobrar-pix-store";

export default function CobrarPIXQRCode() {
  const navigate = useNavigate();
  const { qrCode } = useCobrarPIXStore();

  return (
    <CleanLayout backAction={() => navigate("/pix/cobrar")}>
      <div className="d-flex flex-column align-items-center justify-content-center border rounded p-3 p-md-4 p-lg-5 m-2">
        <p className="h2 text-center mb-5">QR Code gerado com sucesso</p>

        <QRCode value={qrCode} />

        <div className="mt-5 mb-4">
          <Info text="Aponte a câmera do celular para escanear o QR Code ou compartilhe o código com o pagador." />
        </div>

        <button
          className="btn btn-primary"
          onClick={() => navigator.clipboard.writeText(qrCode)}
        >
          Copiar código
        </button>
      </div>
    </CleanLayout>
  );
}
