import BoletoTipo from "../enums/boleto-tipo";

export default function convertEnumBoletoTipoToString(
  boletoTipo: BoletoTipo,
): string {
  switch (boletoTipo) {
    case BoletoTipo.Titulo:
      return "Títulos gerais";
    case BoletoTipo.ContaConsumo:
      return "Contas de consumo";
    case BoletoTipo.TributoApenasCodigoDeBarras:
      return "Outros tributos";
    case BoletoTipo.TributoDarfCodigoDeBarras:
      return "DARF";
    default:
      return "Tipo de boleto não reconhecido";
  }
}
