import {
  FaArrowRightArrowLeft,
  FaChevronLeft,
  FaShieldHalved,
  FaUser,
} from "react-icons/fa6";
import { IoNewspaperOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import MainLayout from "../../../components/Layouts/main";
import { Steps } from "../../../components/Steps";
import { useEnvioPIXStore } from "../../../stores/enviar-pix-store";
import { DadosPessoais } from "./components/dados-pessoais";
import { Inclusao } from "./components/inclusao";
import { Resumo } from "./components/resumo";
import { ValorData } from "./components/valor-data";

export default function EnviarPIX() {
  const { step } = useEnvioPIXStore();

  return (
    <MainLayout>
      <div className="d-flex flex-column mb-4">
        <Link
          to="/pix"
          className="d-flex align-items-center mb-4 text-decoration-none"
        >
          <FaChevronLeft className="text-black" />
          <span className="text-uppercase fw-bold text-black  mx-3">
            Voltar
          </span>
        </Link>
        <p className="h2 m-0">Enviar PIX</p>
      </div>

      <Steps
        currentStep={step}
        items={[
          { icon: FaUser, text: "DADOS PESSOAIS" },
          { icon: FaArrowRightArrowLeft, text: "VALOR" },
          // { icon: IoNewspaperOutline, text: "RESUMO" },
          { icon: FaShieldHalved, text: "INCLUSÃO" },
        ]}
      />
      <div className="bg-white border rounded-bottom d-flex flex-column mb-2">
        {step === 1 && <DadosPessoais />}
        {step === 2 && <ValorData />}
        {step === 3 && <Resumo />}
        {step === 4 && <Inclusao />}
      </div>
    </MainLayout>
  );
}
