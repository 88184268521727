import { FaChevronLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";
import MainLayout from "../../../components/Layouts/main";
import { TabelaExtrato } from "./components/tabela-extrato";

export default function ExtratoPIX() {
  return (
    <MainLayout>
      <div className="d-flex flex-column mb-4">
        <Link
          to="/pix"
          className="d-flex align-items-center mb-4 text-decoration-none"
        >
          <FaChevronLeft className="text-black" />
          <span className="text-uppercase fw-bold text-black mx-3">Voltar</span>
        </Link>
        <p className="h2 m-0">Extrato PIX</p>
      </div>

      <TabelaExtrato />
    </MainLayout>
  );
}
