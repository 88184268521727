import { useForm } from "react-hook-form";
import Input from "../../../../components/Input";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import BoletosRecebimentosPostArgs from "../../../../interfaces/args/boletos-recebimentos-post.args";
import { useEmissaoBoletoStore } from "../../../../stores/emissao-boleto-store";
import InputMask from "react-input-mask";
import clsx from "clsx";
import somenteNumeros from "../../../../utils/somente-numeros";
import toast from "react-hot-toast";
import InputCurrency from "../../../../components/InputCurrency";
import moment from "moment";
import api from "../../../../services/api-client";
import apiErrorHandler from "../../../../services/api-error-handler";
import { useState } from "react";
import PessoaNatureza from "../../../../enums/pessoa-natureza";
import BoletoMoraTipo from "../../../../enums/boleto-mora-tipo";
import BoletoMultaTipo from "../../../../enums/boleto-multa-tipo";

export const InformacoesCobranca = () => {
  const { args, setArgs, setStep } = useEmissaoBoletoStore();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm<BoletosRecebimentosPostArgs>({
    values: args,
  });

  async function buscarCep(e: React.FocusEvent<HTMLInputElement>) {
    const cep = somenteNumeros(e.target.value);

    if (cep.length !== 8) {
      return;
    }

    setValue("pagadorEndereco.logradouro", "...");
    setValue("pagadorEndereco.bairro", "...");
    setValue("pagadorEndereco.cidade", "...");
    setValue("pagadorEndereco.unidadeFederacao", "...");

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.erro) {
          toast.error("CEP não encontrado");
          setValue("pagadorEndereco.logradouro", "");
          setValue("pagadorEndereco.bairro", "");
          setValue("pagadorEndereco.cidade", "");
          setValue("pagadorEndereco.unidadeFederacao", "");
          return;
        }

        const { logradouro, bairro, localidade, uf } = data;

        setValue("pagadorEndereco.logradouro", logradouro);
        setValue("pagadorEndereco.bairro", bairro);
        setValue("pagadorEndereco.cidade", localidade);
        setValue("pagadorEndereco.unidadeFederacao", uf);

        clearErrors("pagadorEndereco.logradouro");
        clearErrors("pagadorEndereco.bairro");
        clearErrors("pagadorEndereco.cidade");
        clearErrors("pagadorEndereco.unidadeFederacao");
      });
  }

  async function onSubmit(data: BoletosRecebimentosPostArgs): Promise<void> {
    if (!moment(data.dataVencimento, "DD/MM/YYYY").isValid()) {
      toast.error("Data de vencimento inválida");
      return;
    }

    if (somenteNumeros(data.pagadorDocumento).length < 11) {
      toast.error("CPF/CNPJ inválido");
      return;
    }

    // setArgs({
    //   ...args,
    //   // informações de cobrança
    //   valorCentavos: data.valorCentavos,
    //   dataVencimento: data.dataVencimento,
    //   numeroIdentificacao: data.numeroIdentificacao,
    //   multaTaxa: data.multaTaxa,
    //   descricao: data.descricao,
    //
    //   // dados do pagador
    //   pagadorNome: data.pagadorNome,
    //   pagadorDocumento: data.pagadorDocumento,
    //   pagadorEmail: data.pagadorEmail,
    //   pagadorTelefone: data.pagadorTelefone,
    //
    //   // endereço do pagador
    //   pagadorEndereco: {
    //     bairro: data.pagadorEndereco.bairro,
    //     cep: data.pagadorEndereco.cep,
    //     cidade: data.pagadorEndereco.cidade,
    //     complemento: data.pagadorEndereco.complemento,
    //     logradouro: data.pagadorEndereco.logradouro,
    //     numero: data.pagadorEndereco.numero,
    //     pais: "Brasil",
    //     unidadeFederacao: data.pagadorEndereco.unidadeFederacao,
    //   },
    // });

    // setStep(2);

    let body: BoletosRecebimentosPostArgs = {
      valorCentavos: Number(somenteNumeros(data.valorCentavos.toString())),
      dataDocumento: null,
      dataVencimento: moment(data.dataVencimento, "DD/MM/YYYY").format(
        "YYYY-MM-DD",
      ),
      descontoTipo: null,
      descontoData: null,
      descontoTaxa: null,
      descontoValorCentavos: null,
      pagadorEndereco: {
        cep: somenteNumeros(data.pagadorEndereco.cep),
        bairro: data.pagadorEndereco.bairro,
        cidade: data.pagadorEndereco.cidade,
        complemento: data.pagadorEndereco.complemento,
        logradouro: data.pagadorEndereco.logradouro,
        numero: data.pagadorEndereco.numero,
        pais: "Brasil",
        unidadeFederacao: data.pagadorEndereco.unidadeFederacao,
      },
      pagadorNome: data.pagadorNome,
      pagadorDocumento: somenteNumeros(data.pagadorDocumento),
      pagadorNatureza:
        somenteNumeros(data.pagadorDocumento).length === 11
          ? PessoaNatureza.Fisica
          : PessoaNatureza.Juridica,
      pagadorEmail: data.pagadorEmail,
      pagadorTelefone: data.pagadorTelefone
        ? somenteNumeros(data.pagadorTelefone)
        : null,
      moraTipo: BoletoMoraTipo.NaoAplicavel,
      moraTaxa: null,
      moraValorCentavos: null,
      multaTipo: BoletoMultaTipo.NaoAplicavel,
      multaTaxa: null,
      multaValorCentavos: null,
      numeroIdentificacao: data.numeroIdentificacao,
      descricao: data.descricao || null,
      callbackAddress: null,
    };

    if (data.multaTaxa && data.multaTaxa > 0) {
      body.multaTipo = BoletoMultaTipo.Percentual;
      body.multaTaxa = Number(data.multaTaxa.toString().replace(",", "."));
    }

    setLoading(true);

    api
      .post("/boletos", body)
      .then(({ data: result }) => {
        console.log(result);
      })
      .catch((err) => {console.log(err);apiErrorHandler(err)})
      .finally(() => setLoading(false));
  }

  return (
    <div className="px-3 px-md-5 mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-lg-5">
          <div className="row">
            <p className="h2 text-center mt-3 mb-4">Informações de cobrança</p>
          </div>

          <div className="row">
            <div className="col-lg-6 mb-3">
              <InputCurrency
                type="text"
                control={control}
                errors={errors}
                label="Valor"
                name="valorCentavos"
                placeholder="R$ 0,00"
                validation={{ required: "Valor é obrigatório" }}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <Input
                type="text"
                control={control}
                errors={errors}
                label="Data de vencimento"
                mask="99/99/9999"
                maskChar="X"
                name="dataVencimento"
                placeholder="XX/XX/XXXX"
                validation={{
                  required: "Data de vencimento é obrigatório",
                }}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <Input
                type="text"
                control={control}
                errors={errors}
                label="Nº Identificação"
                name="numeroIdentificacao"
                placeholder="Insira o número de identificação"
                validation={{
                  required: "Número de identificação é obrigatório",
                }}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <Input
                type="text"
                control={control}
                errors={errors}
                label="Multa após vencimento (porcentagem)"
                name="multaTaxa"
                placeholder="0%"
                validation={{
                  pattern: {
                    // decimal number with 2 decimal places only, separated by comma or dot and has to be greater than 0 and less than 100
                    value: /^(?!0\d)(\d{1,2}([.,]\d+)?|100([.,]0+)?)$/,
                    message: "Valor inválido",
                  },
                }}
              />
            </div>
            <div className="col-lg-12 mb-5">
              <Input
                type="text"
                control={control}
                errors={errors}
                label="Descrição (Opcional)"
                name="descricao"
                placeholder="Insira sua descrição aqui"
                validation={{}}
              />
            </div>
          </div>

          <div className="row">
            <p className="h2 text-center mb-3">Dados do pagador</p>
          </div>

          <div className="row">
            <div className="col-lg-6 mb-3">
              <Input
                type="text"
                control={control}
                errors={errors}
                label="Nome completo/Razão Social (requerido)"
                name="pagadorNome"
                placeholder="Nome completo/Razão Social"
                validation={{
                  required: "Nome completo/Razão Social é obrigatório",
                }}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <Input
                type="text"
                control={control}
                errors={errors}
                label="CPF/CNPJ (requerido)"
                name="pagadorDocumento"
                placeholder="CPF/CNPJ"
                validation={{
                  required: "CPF/CNPJ é obrigatório",
                }}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <Input
                type="text"
                control={control}
                errors={errors}
                label="E-mail (requerido)"
                name="pagadorEmail"
                placeholder="E-mail"
                validation={{
                  required: "E-mail é obrigatório",
                }}
              />
            </div>
            <div className="col-lg-6 mb-5">
              <Input
                type="text"
                control={control}
                errors={errors}
                label="Telefone"
                name="pagadorTelefone"
                placeholder="Telefone"
                validation={{}}
              />
            </div>
          </div>

          <div className="row">
            <p className="h2 text-center mb-3">Endereço de cobrança</p>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-3">
              <label htmlFor="pagadorEndereco.cep" className="form-label">
                CEP
              </label>

              <InputMask
                {...register("pagadorEndereco.cep", {
                  required: "CEP é obrigatório",
                  pattern: {
                    value: /^\d{5}-\d{3}$/,
                    message: "CEP inválido",
                  },
                })}
                className={clsx(
                  "form-control",
                  errors.pagadorEndereco?.cep && "is-invalid border-danger",
                )}
                mask="99999-999"
                maskChar="_"
                type="text"
                placeholder="_____-___"
                onBlur={buscarCep}
              />

              {errors.pagadorEndereco?.cep && (
                <p className="text-danger m-0">
                  {errors.pagadorEndereco.cep.message}
                </p>
              )}
            </div>

            <div className="col-lg-12 mb-3">
              <label
                htmlFor="pagadorEndereco.lougradouro"
                className="form-label"
              >
                Rua
              </label>

              <input
                {...register("pagadorEndereco.logradouro", {
                  required: "Logradouro é obrigatório",
                })}
                type="text"
                className={clsx(
                  "form-control",
                  errors.pagadorEndereco?.logradouro &&
                    "is-invalid border-danger",
                )}
                placeholder="Logradouro"
              />

              {errors.pagadorEndereco?.logradouro && (
                <p className="text-danger m-0">
                  {errors.pagadorEndereco.logradouro.message}
                </p>
              )}
            </div>

            <div className="col-lg-6 mb-3">
              <label htmlFor="pagadorEndereco.numero" className="form-label">
                Número
              </label>
              <input
                {...register("pagadorEndereco.numero", {
                  required: "Número é obrigatório",
                })}
                type="text"
                className={clsx(
                  "form-control",
                  errors.pagadorEndereco?.numero && "is-invalid border-danger",
                )}
                placeholder="Número"
              />

              {errors.pagadorEndereco?.numero && (
                <p className="text-danger m-0">
                  {errors.pagadorEndereco.numero.message}
                </p>
              )}
            </div>

            <div className="col-lg-6 mb-3">
              <label
                htmlFor="pagadorEndereco.complemento"
                className="form-label"
              >
                Complemento
              </label>
              <input
                {...register("pagadorEndereco.complemento")}
                type="text"
                className="form-control"
                placeholder="Apto, casa..."
              />
            </div>

            <div className="col-lg-12 mb-3">
              <label htmlFor="pagadorEndereco.bairro" className="form-label">
                Bairro
              </label>

              <input
                {...register("pagadorEndereco.bairro", {
                  required: "Bairro é obrigatório",
                })}
                type="text"
                className={clsx(
                  "form-control",
                  errors.pagadorEndereco?.bairro && "is-invalid border-danger",
                )}
                placeholder="Bairro"
              />

              {errors.pagadorEndereco?.bairro && (
                <p className="text-danger m-0">
                  {errors.pagadorEndereco.bairro.message}
                </p>
              )}
            </div>

            <div className="col-lg-12 mb-3">
              <label
                htmlFor="pagadorEndereco.unidadeFederacao"
                className="form-label"
              >
                Estado
              </label>

              <input
                {...register("pagadorEndereco.unidadeFederacao", {
                  required: "Estado é obrigatório",
                })}
                type="text"
                className={clsx(
                  "form-control",
                  errors.pagadorEndereco?.unidadeFederacao &&
                    "is-invalid border-danger",
                )}
                placeholder="Estado"
              />

              {errors.pagadorEndereco?.unidadeFederacao && (
                <p className="text-danger m-0">
                  {errors.pagadorEndereco.unidadeFederacao.message}
                </p>
              )}
            </div>

            <div className="col-lg-12 mb-3">
              <label htmlFor="pagadorEndereco.cidade" className="form-label">
                Cidade
              </label>

              <input
                {...register("pagadorEndereco.cidade", {
                  required: "Cidade é obrigatória",
                })}
                type="text"
                className={clsx(
                  "form-control",
                  errors.pagadorEndereco?.cidade && "is-invalid border-danger",
                )}
                placeholder="Cidade"
              />

              {errors.pagadorEndereco?.cidade && (
                <p className="text-danger m-0">
                  {errors.pagadorEndereco.cidade.message}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* FORM BUTTONS */}
        <div className="p-3 p-md-4 d-flex flex-md-row justify-content-between justify-content-md-end align-items-center">
          <Link
            to="/boletos"
            className="d-flex align-items-center text-decoration-none cursor-pointer m-0 me-md-4"
          >
            <FaChevronLeft className="text-black" />
            <span className="text-uppercase fw-bold text-black ms-2">
              Voltar
            </span>
          </Link>
          <button type="submit" disabled={loading} className="btn btn-primary">
            {loading ? "Emitindo..." : "Emitir boleto"}
          </button>
        </div>
      </form>
    </div>
  );
};
