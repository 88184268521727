import { IconProfile } from "../../components/Icons/profile";
import { IconLogout } from "../../components/Icons/logout";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDadosContaStore } from "../../stores/dados-conta-store";

export default function Header() {
  const navigate = useNavigate();
  const { dadosPessoais } = useDadosContaStore();

  return (
    <div
      className="d-flex align-items-center justify-content-between p-3 header"
      style={{ background: "#fff" }}
    >
      <form className="d-flex form-search mx-4 w-50">
        <input
          type="search"
          className="search-input form-control me-2 border-1"
          placeholder="Busque"
          aria-label="Search"
          style={{ height: "45px" }}
        />
        <button
          className="btn input-group-text btn-search border-top border-end border-bottom"
          type="submit"
        >
          <FaSearch />
        </button>
      </form>

      <div className="d-flex align-items-center">
        {/*
        <div>
          <button
            type="button"
            className="bg-transparent border-0"
            data-bs-toggle="offcanvas"
            data-bs-target="#staticBackdropNotification"
            aria-controls="staticBackdrop"
          >
            <IconNotificationActive />
          </button>
          <div
            className="offcanvas offcanvas-end"
            data-bs-backdrop="static"
            tabIndex={-1}
            id="staticBackdropNotification"
            aria-labelledby="staticBackdropLabel"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="p-3">
                <h4 className="fw-semibold">Notificações</h4>
              </div>
            </div>
          </div>
  </div>*/}
        <div className="d-flex align-items-center mx-4">
          <div className="nav-item dropdown">
            <button
              className="nav-link dropdown-toggle d-flex align-items-center"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="/image-profile.png"
                alt="perfil"
                className="rounded-circle cursor-pointer"
                width={54}
                height={54}
              />
              <div className="d-none d-md-none d-lg-flex flex-column align-items-start mx-3">
                <span className="fw-semibold">
                  {dadosPessoais?.nomeCompleto?.split(" ")[0]}
                </span>
                <span className="">
                  Conta: {dadosPessoais?.conta?.numero}-
                  {dadosPessoais?.conta?.digito}
                </span>
              </div>
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end pt-0 pt-lg-2"
              arial-labelledby="navbarDropdown"
            >
              <li>
                <Link
                  to="/perfil"
                  className="dropdown-item d-flex align-items-center"
                >
                  <IconProfile />
                  <span className="mx-2">Minha Conta</span>
                </Link>
              </li>
              {/*<li>
                <Link to={""} className="dropdown-item d-flex align-items-center">
                  <IconGerenciar />
                  <span className="mx-2">Gerenciar Operadores</span>
                </Link>
              </li>*/}
              <li>
                <p
                  onClick={() => {
                    sessionStorage.clear();
                    navigate("/login");
                  }}
                  className="m-0 mt-2 cursor-pointer dropdown-item d-flex align-items-center"
                >
                  <IconLogout />
                  <span className="mx-2">Logout</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
