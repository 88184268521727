import toast from "react-hot-toast";
import AuthDadosPessoaisModel from "../interfaces/models/auth-dados-pessoais-model";
import ServiceResult from "../interfaces/service-result";
import api from "../services/api-client";
import { useDadosContaStore } from "../stores/dados-conta-store";
import { useUpdateSaldo } from "./useUpdateSaldo";

export function useGetDadosConta() {
  const { setDadosPessoais } = useDadosContaStore();
  const updateSaldo = useUpdateSaldo();

  return async function getDadosConta() {
    api
      .get<ServiceResult<AuthDadosPessoaisModel>>("Auth/dados-pessoais")
      .then(({ data: result }) => {
        setDadosPessoais(result.data as AuthDadosPessoaisModel);
        updateSaldo();
      })
      .catch(() => {
        toast.error("Não foi possível obter os dados da sua conta!");
      });
  };
}
