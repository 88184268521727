import { FaFileLines, FaUser } from "react-icons/fa6";
import { useCadastroPFStore } from "../../../../stores/cadastro-pf-store";

export const Header = () => {
  const { step } = useCadastroPFStore();

  return (
    <div
      className="row p-0 mr-n5 sticky-top"
      style={{ backgroundColor: "#fafafa" }}
    >
      <div className="px-4 d-flex justify-content-evenly align-items-center flex-row">
        <div className="px-2 py-3 col-sm-3 d-flex flex-column justify-content-center align-items-center">
          <FaUser style={{ fontSize: "1.3em" }} />
          <p className="p-0 m-0 text-center" style={{ fontSize: ".7em" }}>
            DADOS PESSOAIS
          </p>
        </div>

        <div className="px-2 col-sm-3 d-flex flex-column justify-content-center align-items-center">
          <FaFileLines style={{ fontSize: "1.3em" }} />
          <p className="p-0 m-0 text-center" style={{ fontSize: ".7em" }}>
            DOCUMENTOS
          </p>
        </div>
      </div>

      <div className="row p-0 m-0">
        {step === "dados-pessoais" && (
          <div className="progress p-0 m-0" style={{ height: "4px" }}>
            <div
              className="progress-bar bg-primary"
              style={{ width: "50%" }}
              role="progressbar"
            ></div>
          </div>
        )}

        {step === "documentos" && (
          <div className="progress p-0 m-0" style={{ height: "4px" }}>
            <div
              className="progress-bar bg-primary"
              style={{ width: "100%" }}
              role="progressbar"
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};
