import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { IoSwapHorizontal } from "react-icons/io5";
import { useDadosContaStore } from "../../../stores/dados-conta-store";
import api from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import apiErrorHandler from "../../../services/api-error-handler";
import AuthModel from "../../../interfaces/models/auth.model";
import { useGetDadosConta } from "../../../hooks/useGetDadosConta";
import clsx from "clsx";
import ContaModel from "../../../interfaces/models/conta-model";

export const InfoConta = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [exibindoContas, setExibindoContas] = useState<boolean>(false);
  const [contas, setContas] = useState<ContaModel[]>([]);

  const { dadosPessoais } = useDadosContaStore();
  const getDadosConta = useGetDadosConta();

  async function obterListaDeContas(): Promise<void> {
    api
      .get<ServiceResult<ContaModel[]>>("/contas")
      .then(({ data: result }) => {
        setContas(result.data as ContaModel[]);
      })
      .catch((err) => apiErrorHandler(err));
  }

  async function alternarConta(id: string): Promise<void> {
    setLoading(true);

    api
      .put<ServiceResult<AuthModel>>(`/contas/${id}/ativar`)
      .then(({ data: result }) => {
        sessionStorage.setItem("auth", JSON.stringify(result.data));
        getDadosConta();
        obterListaDeContas();
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    obterListaDeContas();
  }, []);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center">
        <p className="semibold-14 mb-1">Core1 Pagamentos S.A.</p>
        {exibindoContas ? (
          <FaChevronUp
            className="cursor-pointer"
            onClick={() => setExibindoContas(false)}
          />
        ) : (
          <FaChevronDown
            className="cursor-pointer"
            onClick={() => setExibindoContas(true)}
          />
        )}
      </div>

      <p className="regular-14 m-0">
        Agência: <strong>{dadosPessoais?.conta?.agencia}</strong>
      </p>
      <p className="regular-14 m-0">
        Conta blindada:{" "}
        <strong>{`${dadosPessoais?.conta?.numero}-${dadosPessoais?.conta?.digito}`}</strong>
      </p>
      <p className="regular-14 m-0">
        Titular: <strong>{dadosPessoais?.conta?.nomeTitular}</strong>
      </p>

      {exibindoContas &&
        contas.length > 0 &&
        contas
          .filter((c) => !c.atual)
          .map((conta, index) => (
            <div key={index}>
              <hr />

              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <p className="semibold-14 mb-1">Core1 Pagamentos S.A.</p>
                  <p className="regular-14 m-0">
                    Agência: <strong>{conta.agencia || ""}</strong>
                  </p>
                  <p className="regular-14 m-0">
                    Conta blindada:{" "}
                    <strong>{`${conta.numero || ""}-${conta.digito || ""}`}</strong>
                  </p>
                  <p className="regular-14 m-0">
                    Titular: <strong>{conta.nomeTitular || ""}</strong>
                  </p>
                </div>

                <div>
                  <button
                    onClick={() => alternarConta(conta.id)}
                    disabled={loading}
                    className={clsx(
                      "btn d-flex flex-column align-items-center border rounded p-1",
                      loading && "disabled",
                    )}
                  >
                    <IoSwapHorizontal className="fs-3" />
                    <p className="regular-14 m-0">Alternar</p>
                  </button>
                </div>
              </div>
            </div>
          ))}

      {exibindoContas && contas.length === 0 && (
        <>
          <hr />

          <p className="regular-14 m-0">
            Você não possui nenhuma outra conta para gerenciar.
          </p>
        </>
      )}
    </div>
  );
};
