import moment from "moment";
import { FaChevronLeft } from "react-icons/fa6";
import { useEnvioPIXStore } from "../../../../stores/enviar-pix-store";
import { convertCentsToCurrencyString } from "../../../../utils/convert-cents-to-currency-string";
import convertEnumChavePixTipoToString from "../../../../utils/convert-enum-chave-pix-tipo-to-string";

export const Resumo = () => {
  const { setStep, consulta, args } = useEnvioPIXStore();

  return (
    <>
      <p className="h2 mx-3 mx-md-5 my-4">Resumo da transação</p>

      <div className="px-3 px-md-5">
        <div className="row border rounded bg-light p-3 p-md-4">
          <div className="row g-2 mb-4 mb-lg-2">
            <div className="col-lg-4">
              <p className="fw-bold m-0">Favorecido</p>
              <p className="m-0">{consulta.pessoaNome}</p>
            </div>
            <div className="col-lg-4">
              <p className="fw-bold m-0">CPF/CNPJ</p>
              <p className="m-0">
                {consulta.pessoaDocumento?.length === 11
                  ? consulta.pessoaDocumento?.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      "$1.$2.$3-$4",
                    ) ?? ""
                  : consulta.pessoaDocumento?.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      "$1.$2.$3/$4-$5",
                    ) ?? ""}
              </p>
            </div>
            <div className="col-lg-4">
              <p className="fw-bold m-0">Chave PIX</p>
              <p className="m-0">
                {convertEnumChavePixTipoToString(consulta.tipo)}:{" "}
                {consulta.chave}
              </p>
            </div>
          </div>

          <div className="row g-2 mb-4 mb-lg-2">
            <div className="col-lg-4">
              <p className="fw-bold m-0">Banco</p>
              <p className="m-0">{consulta.bancoNome}</p>
            </div>
            <div className="col-lg-4">
              <p className="fw-bold m-0">Agência</p>
              <p className="m-0">
                {(consulta.agencia?.length ?? 0) > 4
                  ? consulta.agencia?.replace(/(\d{4})(\d{1})/, "$1-$2")
                  : consulta.agencia}
              </p>
            </div>
            <div className="col-lg-4">
              <p className="fw-bold m-0">Conta</p>
              <p className="m-0">{consulta.contaNumero}</p>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-lg-4">
              <p className="fw-bold m-0">Valor</p>
              <p className="m-0">
                {convertCentsToCurrencyString(args.valorCentavos)}
              </p>
            </div>
            <div className="col-lg-4">
              <p className="fw-bold m-0">Data</p>
              <p className="m-0">{moment().format("DD/MM/YYYY")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="p-3 p-md-4 d-flex flex-md-row justify-content-between justify-content-md-end align-items-center">
        <p
          onClick={() => setStep(2)}
          className="d-flex align-items-center text-decoration-none cursor-pointer m-0 me-md-4"
        >
          <FaChevronLeft className="text-black" />
          <span className="text-uppercase fw-bold text-black ms-2">Voltar</span>
        </p>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setStep(4)}
        >
          Avançar
        </button>
      </div>
    </>
  );
};
