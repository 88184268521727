import { useEffect, useState } from "react";
import { FaEllipsisV, FaSearch } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { TablePagination } from "../../../components/table-pagination";
import TransacaoBoletoModel from "../../../interfaces/models/transacao-boleto.model";
import api from "../../../services/api-client";
import apiErrorHandler, {
  getApiErrorMessage,
} from "../../../services/api-error-handler";
import ListServiceResult from "../../../interfaces/list-service-result";
import { Loading } from "../../../components/Loading";
import { mascaraCNPJ, mascaraCPF } from "../../../utils/mascara";
import moment from "moment";
import { convertCentsToCurrencyString } from "../../../utils/convert-cents-to-currency-string";
import toast from "react-hot-toast";
import { FaDownload } from "react-icons/fa6";
import { NoData } from "../../../components/NoData";
import { useForm } from "react-hook-form";

export const TableBoleto = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transacoes, setTransacoes] = useState<TransacaoBoletoModel[]>([]);
  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const pesquisa = searchParams.get("q") || "";
  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<{ pesquisa: string }>();

  function handleSearch(data: { pesquisa: string }): void {
    setSearchParams((state) => {
      state.set("q", data.pesquisa);

      return state;
    });
  }

  async function obterBoletos(): Promise<void> {
    setLoading(true);

    api
      .get<ListServiceResult<TransacaoBoletoModel>>(
        `/boletos/extrato?searchString=${pesquisa}&pagina=${pagina}&paginaTamanho=${paginaTamanho}`,
      )
      .then(({ data: result }) => {
        setTransacoes(result.data || []);
        setNumeroPaginas(result.pages || 0);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  async function handleDownloadPdf(id: string): Promise<void> {
    toast.promise(
      api
        .get(`/boletos/${id}/download`, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `boleto-${id}.pdf`);
          document.body.appendChild(link);
          link.click();
        }),
      {
        loading: "Aguarde, estamos preparando o PDF para download...",
        success: "Download concluído com sucesso!",
        error: (err) => getApiErrorMessage(err),
      },
    );
  }

  useEffect(() => {
    obterBoletos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pesquisa, pagina, paginaTamanho]);

  return (
    <>
      <div className="row d-flex justify-content-lg-end mb-4">
        {/* SearchBar */}
        <div className="col-lg-4">
          <form
            className="d-flex flex-column form-search mt-2"
            onSubmit={handleSubmit(handleSearch)}
          >
            <div className="d-flex align-items-center">
              {pesquisa && (
                <button
                  type="button"
                  onClick={() => {
                    if (pesquisa) {
                      setSearchParams((state) => {
                        state.delete("q");
                        return state;
                      });

                      reset();
                    }
                  }}
                  className="me-3 regular-14 btn btn-secondary border-0"
                  style={{ height: 54, width: 139 }}
                >
                  Limpar pesquisa
                </button>
              )}

              <input
                {...register("pesquisa")}
                type="search"
                className="search-input form-control me-2 border-1"
                placeholder="Busque por um nome ou documento"
              />

              <button
                className="btn input-group-text btn-search border-top border-end border-bottom"
                style={{ height: 54 }}
                type="submit"
              >
                <FaSearch />
              </button>
            </div>

            {errors.pesquisa && (
              <span className="text-danger">{errors.pesquisa.message}</span>
            )}
          </form>
        </div>
      </div>

      {loading && (
        <div className="row mx-0">
          <div className="col-sm-12 p-0">
            <div className="d-flex justify-content-center align-items-center">
              <Loading />
            </div>
          </div>
        </div>
      )}

      {!loading && transacoes.length > 0 && (
        <div className="row mx-0">
          <div className="col-sm-12 mb-4 p-0 table-boleto table-responsive-stack">
            <table className="table m-0" style={{ color: "#131E2E" }}>
              <thead>
                <tr>
                  <th scope="col" className="py-3 ps-3">
                    Nome e documento
                  </th>
                  <th scope="col" className="py-3">
                    Data de Adição
                  </th>
                  <th scope="col" className="py-3">
                    Valor / Descrição
                  </th>
                  <th scope="col" className="py-3">
                    Status
                  </th>
                  <th scope="col" className="py-3"></th>
                </tr>
              </thead>
              <tbody>
                {transacoes.map((t, index) => (
                  <tr className="align-middle" key={index}>
                    <td className="py-3 ps-3">
                      <div className="d-flex flex-column">
                        <p className="m-0 medium-14">{t.pagadorNome}</p>
                        <p className="m-0 regular-14">
                          {t.pagadorDocumento.length === 11
                            ? mascaraCPF(t.pagadorDocumento)
                            : mascaraCNPJ(t.pagadorDocumento)}
                        </p>
                      </div>
                    </td>
                    <td className="py-3">
                      <div className="d-flex flex-column">
                        <p className="m-0 medium-14">
                          {moment(t.criadoEm)
                            .add(-3, "hours")
                            .format("DD/MM/YYYY")}
                        </p>
                        <p className="m-0 regular-14">
                          {moment(t.criadoEm)
                            .add(-3, "hours")
                            .format("HH[h]mm[m]")}
                        </p>
                      </div>
                    </td>
                    <td className="py-3">
                      <div className="d-flex flex-column">
                        <p className="m-0 medium-14">
                          {convertCentsToCurrencyString(t.valorCentavos)}
                        </p>
                        <p className="m-0 regular-14">{t.descricao}</p>
                      </div>
                    </td>
                    <td className="py-3">
                      <div>
                        <p className="m-0 medium-14 text-success">
                          {t.situacao}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <div
                          className="dropdown cursor-pointer"
                          data-bs-toggle="dropdown"
                        >
                          <FaEllipsisV />
                        </div>
                        <ul className="dropdown-menu mt-2">
                          {/*
                          <li>
                            <Link
                              to={`/extrato/boleto/${t.id}`}
                              className="dropdown-item d-flex align-items-center"
                            >
                              <FaFileAlt className="fs-5 me-2" />
                              Ver boleto
                            </Link>
                          </li>

                          <div className="dropdown-divider"></div>
                          */}

                          <li>
                            <button
                              onClick={() => handleDownloadPdf(t.id)}
                              className="dropdown-item d-flex align-items-center"
                            >
                              <FaDownload className="fs-5 me-2" />
                              Download (PDF)
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <TablePagination numeroPaginas={numeroPaginas} />
        </div>
      )}

      {!loading && transacoes.length === 0 && (
        <NoData
          title="Você ainda não possui boletos gerados"
          description="Não foi encontrado nenhum registro no seu histórico de boletos. Assim que houver, estará disponível bem aqui."
        />
      )}
    </>
  );
};
