import clsx from "clsx";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { FaFileUpload } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import ReactInputMask from "react-input-mask";
import EstadoCivil from "../../../../enums/estado-civil";
import PessoaNatureza from "../../../../enums/pessoa-natureza";
import PropostaSocioArgs from "../../../../interfaces/args/proposta-socio.args";
import PropostaSocioModel from "../../../../interfaces/models/proposta-socio.model";
import ServiceResult from "../../../../interfaces/service-result";
import api from "../../../../services/api-client";
import apiErrorHandler from "../../../../services/api-error-handler";
import { useCadastroPJStore } from "../../../../stores/cadastro-pj-store";
import somenteNumeros from "../../../../utils/somente-numeros";
import { ListaSociosCadastrados } from "./lista-socios-cadastrados";

export const AdicioneSocios = () => {
  const propostaId: string = localStorage.getItem("propostaId") ?? "";

  const {
    socios,
    setSocios,
    setStep,
    socioFotoDocumentoFile,
    socioSelfieDocumentoFile,
    socioAtosConstitutivosFile,
  } = useCadastroPJStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [cadastrandoSocio, setCadastrandoSocio] = useState<boolean>(
    socios.length === 0,
  );
  const [naturezaSocio, setNaturezaSocio] = useState<PessoaNatureza>(
    PessoaNatureza.Fisica,
  );

  const fotoDocumentoRef = useRef<HTMLInputElement | null>(null);
  const selfieDocumentoRef = useRef<HTMLInputElement | null>(null);
  const atosConstitutivosRef = useRef<HTMLInputElement | null>(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<PropostaSocioArgs>({
    values: {} as PropostaSocioArgs,
  });

  function handleSocioFileUpload(
    e: React.ChangeEvent<HTMLInputElement>,
    type: "fotoDocumento" | "selfieDocumento" | "atosConstitutivos",
  ) {
    const files = e.target.files;

    if (!files?.length) {
      return;
    }

    const file = files[0];

    switch (type) {
      case "fotoDocumento":
        useCadastroPJStore.setState({
          socioFotoDocumentoFile: file,
        });
        break;

      case "selfieDocumento":
        useCadastroPJStore.setState({
          socioSelfieDocumentoFile: file,
        });
        break;

      case "atosConstitutivos":
        useCadastroPJStore.setState({
          socioAtosConstitutivosFile: file,
        });
        break;

      default:
        break;
    }
  }

  function validate(): boolean {
    if (!cadastrandoSocio && socios.length === 0) {
      toast.error("Adicione ao menos um sócio para continuar");
      return false;
    }

    const cadastrandoSocioPF =
      cadastrandoSocio && naturezaSocio === PessoaNatureza.Fisica;

    const cadastrandoSocioPJ =
      cadastrandoSocio && naturezaSocio === PessoaNatureza.Juridica;

    if (
      cadastrandoSocioPF &&
      !socioFotoDocumentoFile &&
      !socioSelfieDocumentoFile
    ) {
      toast.error(
        <p className="m-0">
          Envie as imagens: <strong>Foto do documento</strong> e{" "}
          <strong>Selfie com documento</strong> do sócio para continuar
        </p>,
      );
      return false;
    }

    if (cadastrandoSocioPF && !socioFotoDocumentoFile) {
      toast.error(
        <p className="m-0">
          Envie a imagem: <strong>Foto do documento</strong> do sócio para
          continuar
        </p>,
      );
      return false;
    }

    if (cadastrandoSocioPF && !socioSelfieDocumentoFile) {
      toast.error(
        <p className="m-0">
          Envie a imagem: <strong>Selfie com documento</strong> do sócio para
          continuar
        </p>,
      );
      return false;
    }

    if (cadastrandoSocioPJ && !socioAtosConstitutivosFile) {
      toast.error(
        <p className="m-0">
          Envie o arquivo: <strong>Atos constitutivos</strong> da empresa do
          sócio para continuar
        </p>,
      );
      return false;
    }

    return true;
  }

  async function onSubmit(data: PropostaSocioArgs): Promise<void> {
    if (!validate()) {
      return;
    }

    if (!cadastrandoSocio) {
      setStep("documentos");
      return;
    }

    data.natureza = naturezaSocio;

    let formData = new FormData();

    let args: PropostaSocioArgs = {} as PropostaSocioArgs;

    if (naturezaSocio === PessoaNatureza.Fisica) {
      args = {
        natureza: PessoaNatureza.Fisica,
        socioPessoaFisica: {
          cpf: somenteNumeros(data.socioPessoaFisica?.cpf ?? ""),
          email: data.socioPessoaFisica?.email ?? "",
          estadoCivil: data.socioPessoaFisica?.estadoCivil as EstadoCivil,
          nacionalidade: data.socioPessoaFisica?.nacionalidade ?? "",
          nascimento: moment(
            data.socioPessoaFisica?.nascimento,
            "DD/MM/YYYY",
          ).format("YYYY-MM-DD"),
          nome: data.socioPessoaFisica?.nome ?? "",
          rg: somenteNumeros(data.socioPessoaFisica?.rg ?? ""),
          rgUf: data.socioPessoaFisica?.rgUf ?? "",
          telefoneContato: somenteNumeros(
            data.socioPessoaFisica?.telefoneContato ?? "",
          ),
        },
        socioPessoaJuridica: null,
      };

      formData.append(
        "Socio.ArquivoFotoDocumento",
        socioFotoDocumentoFile!,
        socioFotoDocumentoFile!.name,
      );
      formData.append(
        "Socio.ArquivoFotoSelfieComDocumento",
        socioSelfieDocumentoFile!,
        socioSelfieDocumentoFile!.name,
      );
    } else {
      args = {
        natureza: PessoaNatureza.Juridica,
        socioPessoaFisica: null,
        socioPessoaJuridica: {
          cnpj: somenteNumeros(data.socioPessoaJuridica?.cnpj ?? ""),
          razaoSocial: data.socioPessoaJuridica?.razaoSocial ?? "",
        },
      };

      formData.append(
        "Socio.ArquivoAtosConstitutivos",
        socioAtosConstitutivosFile!,
        socioAtosConstitutivosFile!.name,
      );
    }

    formData.append("jsonData", JSON.stringify(args));

    api
      .postForm<ServiceResult<string>>(
        `/propostas/${propostaId}/socios`,
        formData,
      )
      .then(() => {
        setCadastrandoSocio(false);
        obterSocios();
        setStep("documentos");
        reset();
        useCadastroPJStore.setState({
          socioFotoDocumentoFile: null,
          socioSelfieDocumentoFile: null,
          socioAtosConstitutivosFile: null,
        });
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  async function obterSocios(): Promise<void> {
    setLoading(true);

    api
      .get<ServiceResult<PropostaSocioModel[]>>(
        `/propostas/${propostaId}/socios`,
      )
      .then(({ data: result }) => {
        setSocios(result.data as PropostaSocioModel[]);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  async function deleteSocio(socioId: string): Promise<void> {
    setLoading(true);

    api
      .delete(`/propostas/${propostaId}/socios/${socioId}`)
      .then(() => {
        toast.success("Sócio removido com sucesso");
        obterSocios();
      })
      .catch((err) => apiErrorHandler(err));
  }

  useEffect(() => {
    obterSocios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-lg-5 mb-5 pb-4">
        <div className="row m-3">
          <p className="h1 mb-4">Adicione sócios</p>
          <p className="h2 mb-3">Sócios</p>
        </div>

        {loading && <p className="text-center regular-16">Carregando...</p>}

        {/* LISTA DE SÓCIOS */}
        {!loading && !cadastrandoSocio && (
          <ListaSociosCadastrados
            deleteSocio={deleteSocio}
            setCadastrandoSocio={setCadastrandoSocio}
          />
        )}

        {/* FORMULÁRIO DE CADASTRO DE SÓCIO */}
        {!loading && cadastrandoSocio && (
          <div className="row m-3">
            <div className="d-flex justify-content-evenly justify-content-md-start align-items-center mb-4">
              <button
                type="button"
                className={clsx(
                  "btn border-0 semibold-16 me-md-4",
                  naturezaSocio === PessoaNatureza.Fisica &&
                    "borda-natureza-cadastro-socio",
                )}
                style={{ color: "#777777" }}
                onClick={() => setNaturezaSocio(PessoaNatureza.Fisica)}
              >
                Pessoa Física
              </button>

              <button
                type="button"
                className={clsx(
                  "btn border-0 semibold-16",
                  naturezaSocio === PessoaNatureza.Juridica &&
                    "borda-natureza-cadastro-socio",
                )}
                style={{ color: "#777777" }}
                onClick={() => setNaturezaSocio(PessoaNatureza.Juridica)}
              >
                Pessoa Jurídica
              </button>
            </div>

            {naturezaSocio === PessoaNatureza.Fisica && (
              <>
                <div className="col-lg-12 mb-3">
                  <label
                    className="form-label"
                    htmlFor="socioPessoaFisica.nome"
                  >
                    Nome completo
                  </label>

                  <input
                    type="text"
                    className={clsx(
                      "form-control",
                      errors.socioPessoaFisica?.nome &&
                        "is-invalid border-danger",
                    )}
                    placeholder="Nome"
                    {...register("socioPessoaFisica.nome", {
                      required: "Nome é obrigatório",
                    })}
                  />

                  {errors.socioPessoaFisica?.nome && (
                    <p className="text-danger m-0">
                      {errors.socioPessoaFisica.nome.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-12 mb-3">
                  <label
                    className="form-label"
                    htmlFor="socioPessoaFisica.email"
                  >
                    E-mail
                  </label>

                  <input
                    type="email"
                    className={clsx(
                      "form-control",
                      errors.socioPessoaFisica?.email &&
                        "is-invalid border-danger",
                    )}
                    placeholder="E-mail"
                    {...register("socioPessoaFisica.email", {
                      required: "E-mail é obrigatório",
                    })}
                  />

                  {errors.socioPessoaFisica?.email && (
                    <p className="text-danger m-0">
                      {errors.socioPessoaFisica.email.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-6 mb-3">
                  <label className="form-label" htmlFor="socioPessoaFisica.cpf">
                    CPF
                  </label>

                  <ReactInputMask
                    mask="999.999.999-99"
                    maskChar="X"
                    type="text"
                    className={clsx(
                      "form-control",
                      errors.socioPessoaFisica?.cpf &&
                        "is-invalid border-danger",
                    )}
                    placeholder="Insira o CPF"
                    {...register("socioPessoaFisica.cpf", {
                      required: "CPF é obrigatório",
                    })}
                  />

                  {errors.socioPessoaFisica?.cpf && (
                    <p className="text-danger m-0">
                      {errors.socioPessoaFisica.cpf.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-6 mb-3">
                  <label
                    className="form-label"
                    htmlFor="socioPessoaFisica.nascimento"
                  >
                    Data de nascimento
                  </label>

                  <ReactInputMask
                    mask="99/99/9999"
                    maskChar="X"
                    type="text"
                    className={clsx(
                      "form-control",
                      errors.socioPessoaFisica?.nascimento &&
                        "is-invalid border-danger",
                    )}
                    placeholder="XX/XX/XXXX"
                    {...register("socioPessoaFisica.nascimento", {
                      required: "Data de nascimento é obrigatória",
                    })}
                  />

                  {errors.socioPessoaFisica?.nascimento && (
                    <p className="text-danger m-0">
                      {errors.socioPessoaFisica.nascimento.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-6 mb-3">
                  <label className="form-label" htmlFor="socioPessoaFisica.rg">
                    RG
                  </label>

                  <input
                    type="text"
                    className={clsx(
                      "form-control",
                      errors.socioPessoaFisica?.rg &&
                        "is-invalid border-danger",
                    )}
                    placeholder="Insira o RG"
                    {...register("socioPessoaFisica.rg", {
                      required: "RG é obrigatório",
                    })}
                  />

                  {errors.socioPessoaFisica?.rg && (
                    <p className="text-danger m-0">
                      {errors.socioPessoaFisica.rg.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-6 mb-3">
                  <label
                    className="form-label"
                    htmlFor="socioPessoaFisica.rgUf"
                  >
                    UF do Documento
                  </label>

                  <input
                    type="text"
                    className={clsx(
                      "form-control text-uppercase",
                      errors.socioPessoaFisica?.rgUf &&
                        "is-invalid border-danger",
                    )}
                    placeholder="UF"
                    {...register("socioPessoaFisica.rgUf", {
                      required: "UF do documento é obrigatório",
                    })}
                    maxLength={2}
                    pattern="[A-Za-z]{2}"
                  />

                  {errors.socioPessoaFisica?.rgUf && (
                    <p className="text-danger m-0">
                      {errors.socioPessoaFisica.rgUf.message}
                    </p>
                  )}
                </div>

                {/* TODO: ABSTRAIR PARA COMPONENTE SELECT */}
                <div className="col-lg-6 mb-3">
                  <label
                    htmlFor="socioPessoaFisica.estadoCivil"
                    className="form-label"
                  >
                    Estado Civil
                  </label>

                  <select
                    {...register("socioPessoaFisica.estadoCivil", {
                      required: "Estado civil é obrigatório",
                    })}
                    defaultValue=""
                    className={clsx(
                      "form-select",
                      errors.socioPessoaFisica?.estadoCivil &&
                        "is-invalid border-danger",
                    )}
                  >
                    <option value="" disabled>
                      Selecione
                    </option>
                    {Object.entries(EstadoCivil).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </select>

                  {errors.socioPessoaFisica?.estadoCivil && (
                    <p className="text-danger m-0">
                      {errors.socioPessoaFisica.estadoCivil.message}
                    </p>
                  )}
                </div>

                {/* TODO: ABSTRAIR PARA COMPONENTE SELECT */}
                <div className="col-lg-6 mb-3">
                  <label
                    htmlFor="socioPessoaFisica.nacionalidade"
                    className="form-label"
                  >
                    Nacionalidade
                  </label>

                  <select
                    {...register("socioPessoaFisica.nacionalidade", {
                      required: "Nacionalidade é obrigatória",
                    })}
                    defaultValue=""
                    className={clsx(
                      "form-select",
                      errors.socioPessoaFisica?.nacionalidade &&
                        "is-invalid border-danger",
                    )}
                  >
                    <option value="" disabled>
                      Selecione
                    </option>
                    <option value="brasileira">Brasileira</option>
                  </select>

                  {errors.socioPessoaFisica?.nacionalidade && (
                    <p className="text-danger m-0">
                      {errors.socioPessoaFisica.nacionalidade.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-12 mb-3">
                  <label className="form-label">
                    Adicione foto do documento
                  </label>

                  {/* Input file hidden */}
                  <input
                    ref={fotoDocumentoRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => handleSocioFileUpload(e, "fotoDocumento")}
                  />

                  <button
                    type="button"
                    className="btn bg-white w-100 d-flex align-items-center justify-content-center"
                    style={{
                      border: "2px solid #d2d6dc",
                      height: 54,
                    }}
                    onClick={() => {
                      if (loading) return;
                      fotoDocumentoRef.current?.click();
                    }}
                  >
                    <FaFileUpload
                      className="me-2"
                      style={{ color: "#777777" }}
                    />
                    <p className="medium-16 m-0" style={{ color: "#777777" }}>
                      Escolher arquivo
                    </p>
                  </button>

                  {socioFotoDocumentoFile && (
                    <div className="max-w-100 text-break mt-2 d-inline-flex justify-content-between align-items-center border border-dark rounded p-2">
                      <p className="m-0">{socioFotoDocumentoFile.name}</p>
                      <IoClose
                        size={20}
                        className="ms-2 cursor-pointer text-danger"
                        onClick={() => {
                          useCadastroPJStore.setState({
                            socioFotoDocumentoFile: null,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className="col-lg-12 mb-3">
                  <label className="form-label">Selfie com documento</label>

                  {/* Input file hidden */}
                  <input
                    ref={selfieDocumentoRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) =>
                      handleSocioFileUpload(e, "selfieDocumento")
                    }
                  />

                  <button
                    type="button"
                    className="btn bg-white w-100 d-flex align-items-center justify-content-center"
                    style={{
                      border: "2px solid #d2d6dc",
                      height: 54,
                    }}
                    onClick={() => {
                      if (loading) return;
                      selfieDocumentoRef.current?.click();
                    }}
                  >
                    <FaFileUpload
                      className="me-2"
                      style={{ color: "#777777" }}
                    />
                    <p className="medium-16 m-0" style={{ color: "#777777" }}>
                      Escolher arquivo
                    </p>
                  </button>

                  {socioSelfieDocumentoFile && (
                    <div className="max-w-100 text-break mt-2 d-inline-flex justify-content-between align-items-center border border-dark rounded p-2">
                      <p className="m-0">{socioSelfieDocumentoFile.name}</p>
                      <IoClose
                        size={20}
                        className="ms-2 cursor-pointer text-danger"
                        onClick={() => {
                          useCadastroPJStore.setState({
                            socioSelfieDocumentoFile: null,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            {naturezaSocio === PessoaNatureza.Juridica && (
              <>
                <div className="col-lg-12 mb-3">
                  <label
                    className="form-label"
                    htmlFor="socioPessoaJuridica.razaoSocial"
                  >
                    Razão Social
                  </label>

                  <input
                    type="text"
                    className={clsx(
                      "form-control",
                      errors.socioPessoaJuridica?.razaoSocial &&
                        "is-invalid border-danger",
                    )}
                    placeholder="Razão Social"
                    {...register("socioPessoaJuridica.razaoSocial", {
                      required: "Razão Social é obrigatória",
                    })}
                  />

                  {errors.socioPessoaJuridica?.razaoSocial && (
                    <p className="text-danger m-0">
                      {errors.socioPessoaJuridica.razaoSocial.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-12 mb-3">
                  <label
                    className="form-label"
                    htmlFor="socioPessoaJuridica.cnpj"
                  >
                    CNPJ
                  </label>

                  <ReactInputMask
                    mask="99.999.999/9999-99"
                    maskChar="X"
                    type="text"
                    className={clsx(
                      "form-control",
                      errors.socioPessoaJuridica?.cnpj &&
                        "is-invalid border-danger",
                    )}
                    placeholder="Insira o CNPJ"
                    {...register("socioPessoaJuridica.cnpj", {
                      required: "CNPJ é obrigatório",
                    })}
                  />

                  {errors.socioPessoaJuridica?.cnpj && (
                    <p className="text-danger m-0">
                      {errors.socioPessoaJuridica.cnpj.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-12 mb-3">
                  <label className="form-label">Atos constitutivos</label>

                  {/* Input file hidden */}
                  <input
                    ref={atosConstitutivosRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) =>
                      handleSocioFileUpload(e, "atosConstitutivos")
                    }
                  />

                  <button
                    type="button"
                    className="btn bg-white w-100 d-flex align-items-center justify-content-center"
                    style={{
                      border: "2px solid #d2d6dc",
                      height: 54,
                    }}
                    onClick={() => {
                      if (loading) return;
                      atosConstitutivosRef.current?.click();
                    }}
                  >
                    <FaFileUpload
                      className="me-2"
                      style={{ color: "#777777" }}
                    />
                    <p className="medium-16 m-0" style={{ color: "#777777" }}>
                      Escolher arquivo
                    </p>
                  </button>

                  {socioAtosConstitutivosFile && (
                    <div className="max-w-100 text-break mt-2 d-inline-flex justify-content-between align-items-center border border-dark rounded p-2">
                      <p className="m-0">{socioAtosConstitutivosFile.name}</p>
                      <IoClose
                        size={20}
                        className="ms-2 cursor-pointer text-danger"
                        onClick={() => {
                          useCadastroPJStore.setState({
                            socioAtosConstitutivosFile: null,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* WEB */}
      <div
        className={
          "position-fixed bottom-0 bg-white w-50 d-none d-lg-flex justify-content-between align-items-center p-3"
        }
        style={{ borderTop: "1px solid #E5E5E5" }}
      >
        <button
          type="button"
          className="btn d-flex align-items-center"
          onClick={() =>
            cadastrandoSocio
              ? setCadastrandoSocio(false)
              : setStep("dados-empresa")
          }
        >
          <FaChevronLeft className="me-2" />
          <p className="semibold-14 m-0 text-uppercase">Voltar</p>
        </button>
        <button type="submit" className="btn btn-primary">
          Avançar
        </button>
      </div>

      {/* MOBILE */}
      <div
        className={
          "position-fixed bottom-0 bg-white w-100 d-flex d-lg-none justify-content-between align-items-center p-3"
        }
        style={{ borderTop: "1px solid #E5E5E5" }}
      >
        <button
          type="button"
          className="btn d-flex align-items-center"
          onClick={() =>
            cadastrandoSocio
              ? setCadastrandoSocio(false)
              : setStep("dados-empresa")
          }
        >
          <FaChevronLeft className="me-2" />
          <p className="semibold-14 m-0 text-uppercase">Voltar</p>
        </button>
        <button type="submit" className="btn btn-primary">
          Avançar
        </button>
      </div>
    </form>
  );
};
