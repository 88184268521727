export const IconPix = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.715"
      height="16.715"
      data-name="Grupo 10868"
      viewBox="0 0 16.715 16.715"
    >
      <g fill="#fff" data-name="Grupo 10867">
        <path
          d="M54.745 145.194a2.44 2.44 0 01-1.736-.719l-2.509-2.507a.477.477 0 00-.659 0l-2.516 2.516a2.44 2.44 0 01-1.736.719H45.1l3.175 3.175a2.539 2.539 0 003.591 0l3.184-3.184z"
          transform="translate(-41.709 -132.408)"
        ></path>
        <path
          d="M45.592 20.206a2.44 2.44 0 011.736.719l2.516 2.517a.466.466 0 00.659 0l2.507-2.507a2.44 2.44 0 011.736-.719h.3l-3.184-3.184a2.539 2.539 0 00-3.591 0L45.1 20.206z"
          transform="translate(-41.711 -16.287)"
        ></path>
        <path
          d="M15.971 79.87l-1.924-1.924a.366.366 0 01-.137.028h-.875a1.729 1.729 0 00-1.215.5l-2.506 2.51a1.2 1.2 0 01-1.7 0L5.1 78.467a1.729 1.729 0 00-1.215-.5h-1.08a.365.365 0 01-.129-.026L.744 79.87a2.539 2.539 0 000 3.591l1.932 1.932a.365.365 0 01.129-.026h1.076a1.729 1.729 0 001.215-.5l2.516-2.516a1.232 1.232 0 011.7 0l2.507 2.507a1.729 1.729 0 001.215.5h.875a.366.366 0 01.137.028l1.924-1.924a2.539 2.539 0 000-3.591"
          transform="translate(0 -73.308)"
        ></path>
      </g>
    </svg>
  );
}
