interface Props {
  children: React.ReactNode;
  page: "cadastro" | "login";
}

export default function AuthLayout({ children, page }: Props) {
  return (
    <div>
      <main role="main" style={{ overflowX: "hidden" }}>
        <div className="container-fluid m-0 p-0">
          <div className="row">
            <div
              className="col-lg-6 position-relative"
              style={{
                maxHeight: "100vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {children}
            </div>
            <div className="col-lg-6 p-0">
              <div
                className="container-img w-100 p-0 d-none d-lg-block"
                style={{
                  backgroundImage: `url(${
                    page === "login"
                      ? process.env.REACT_APP_BANNER_LOGIN
                      : process.env.REACT_APP_BANNER_CADASTRO
                  })`,
                  minHeight: "100vh",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
