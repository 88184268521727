import { create } from "zustand";
import PixEnviosPostArgs from "../interfaces/args/pix-envios-post.args";
import PixConsultaModel from "../interfaces/models/pix-consulta.model";

type EnvioPIXStore = {
  step: 1 | 2 | 3 | 4;
  setStep: (step: 1 | 2 | 3 | 4) => void;

  consulta: PixConsultaModel;
  setConsulta: (resumo: PixConsultaModel) => void;

  args: PixEnviosPostArgs;
  setArgs: (args: PixEnviosPostArgs) => void;
};

export const useEnvioPIXStore = create<EnvioPIXStore>()((set) => ({
  step: 1,
  setStep: (step) => {
    set((state) => {
      return {
        ...state,
        step,
      };
    });
  },

  consulta: {} as PixConsultaModel,
  setConsulta: (consulta) => {
    set((state) => {
      return {
        ...state,
        consulta,
      };
    });
  },

  args: {} as PixEnviosPostArgs,
  setArgs: (args) => {
    set((state) => {
      return {
        ...state,
        args,
      };
    });
  },
}));
