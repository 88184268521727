import { useState } from "react";
import { toast } from "react-hot-toast";
import { FiPlusCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import EmptyModal from "../../../../components/Modals/empty-modal";
import PessoaNatureza from "../../../../enums/pessoa-natureza";
import PropostaSocioModel from "../../../../interfaces/models/proposta-socio.model";
import { useCadastroPJStore } from "../../../../stores/cadastro-pj-store";

interface Props {
  setCadastrandoSocio: React.Dispatch<React.SetStateAction<boolean>>;
  deleteSocio: (socioId: string) => Promise<void>;
}

export const ListaSociosCadastrados = ({
  deleteSocio,
  setCadastrandoSocio,
}: Props) => {
  const { socios } = useCadastroPJStore();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [socioAux, setSocioAux] = useState<PropostaSocioModel | null>(null);

  function handleModalOpen(socio: PropostaSocioModel): void {
    setIsModalOpen(true);
    setSocioAux(socio);
  }

  function handleModalClose(): void {
    setIsModalOpen(false);
    setSocioAux(null);
  }

  async function handleDelete(): Promise<void> {
    deleteSocio(socioAux!.id);
    handleModalClose();
  }

  function getSocioNome(socio: PropostaSocioModel): string {
    return socio.natureza === PessoaNatureza.Fisica
      ? socio.socioPessoaFisica?.nome?.split(" ")[0] ?? ""
      : socio.socioPessoaJuridica?.razaoSocial?.split(" ")[0] ?? "";
  }

  function getSocioNomeCompleto(socio: PropostaSocioModel | null): string {
    return socio?.natureza === PessoaNatureza.Fisica
      ? socio.socioPessoaFisica?.nome ?? ""
      : socio?.socioPessoaJuridica?.razaoSocial ?? "";
  }

  function getSocioNatureza(socio: PropostaSocioModel): string {
    return socio.natureza === PessoaNatureza.Fisica
      ? "Pessoa Física"
      : "Pessoa Jurídica";
  }

  return (
    <div className="row m-3">
      <div className="col-lg-12 d-flex flex-column mb-3">
        {socios.map((socio, index) => (
          <div
            key={index}
            className="mb-2 p-3 w-100 d-flex flex-wrap justify-content-between align-items-center"
            style={{ border: "2px solid #D3D3D34D" }}
          >
            <p className="text-black medium-16 m-0">{getSocioNome(socio)}</p>

            <div className="d-flex align-items-center">
              <div className="regular-14 borda-natureza-listagem-socio p-1">
                {getSocioNatureza(socio)}
              </div>

              <IoClose
                onClick={() => handleModalOpen(socio)}
                className="ms-3 fs-4 text-danger cursor-pointer"
              />
            </div>
          </div>
        ))}

        <div className="col-lg-12 my-3">
          <button
            type="button"
            className="w-100 p-3 semibold-16 d-flex justify-content-center align-items-center btn"
            style={{
              border: "2px dashed #D0D0D0",
              borderRadius: 6,
              color: "#777777",
            }}
            onClick={() => setCadastrandoSocio(true)}
          >
            <FiPlusCircle className="me-2 fs-4" />
            Adicionar novo sócio
          </button>
        </div>
      </div>

      {isModalOpen && (
        <EmptyModal
          isOpen={isModalOpen}
          title="Remover sócio"
          close={handleModalClose}
          overlayClose
        >
          <p className="regular-16 mb-4">
            Tem certeza que deseja remover o sócio:{" "}
            <strong>{getSocioNomeCompleto(socioAux)} </strong>?
          </p>

          <button
            type="button"
            className="w-100 btn btn-primary"
            onClick={handleDelete}
          >
            Sim, remover
          </button>

          <button
            type="button"
            className="w-100 mt-2 rounded-pill btn btn-light"
            onClick={handleModalClose}
          >
            Cancelar
          </button>
        </EmptyModal>
      )}
    </div>
  );
};
