enum TransacaoTipo {
  // Boletos 0~99
  BoletoRecebimento = "BoletoRecebimento",
  BoletoRecebimentoTarifa = "BoletoRecebimentoTarifa",
  BoletoRecebimentoInstrucaoTarifa = "BoletoRecebimentoInstrucaoTarifa",
  BoletoPagamento = "BoletoPagamento",
  BoletoPagamentoTarifa = "BoletoPagamentoTarifa",
  BoletoRecebimentoCompensacaoTarifa = "BoletoRecebimentoCompensacaoTarifa",
  // PeerToPeer 100~199
  PeerToPeerEnvio = "PeerToPeerEnvio",
  PeerToPeerRecebimento = "PeerToPeerRecebimento",
  // Pix 200~299
  PixRecebimento = "PixRecebimento",
  PixRecebimentoTarifa = "PixRecebimentoTarifa",
  PixEnvio = "PixEnvio",
  PixEnvioTarifa = "PixEnvioTarifa",
  PixDevolucaoTarifa = "PixDevolucaoTarifa",
  PixRecebimentoDevolucao = "PixRecebimentoDevolucao",
  PixRecebimentoDevolucaoTarifa = "PixRecebimentoDevolucaoTarifa",
  PixEnvioDevolucao = "PixEnvioDevolucao",
  PixEnvioDevolucaoTarifa = "PixEnvioDevolucaoTarifa",
  // TED 300~399
  TedEnvio = "TedEnvio",
  TedEnvioTarifa = "TedEnvioTarifa",
  TedRecebimento = "TedRecebimento",
  TedRecebimentoTarifa = "TedRecebimentoTarifa",
  // Pagametnos 400~499
  PagamentoTitulo = "PagamentoTitulo",
  PagamentoContaConsumo = "PagamentoContaConsumo",
  // Outros 500~599
  ManutencaoContaTarifa = "ManutencaoContaTarifa",
}

export default TransacaoTipo;
