import { Link, useParams } from "react-router-dom";
import MainLayout from "../../../components/Layouts/main";
import { FaChevronLeft } from "react-icons/fa";
import { VscExport } from "react-icons/vsc";
import { useEffect, useState } from "react";
import TransacaoPixModel from "../../../interfaces/models/transacao-pix.model";
import api from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import apiErrorHandler from "../../../services/api-error-handler";
import moment from "moment";
import { convertCentsToCurrencyString } from "../../../utils/convert-cents-to-currency-string";

export default function DetalhesPix() {
  const { pixId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [detalhes, setDetalhes] = useState<TransacaoPixModel>(
    {} as TransacaoPixModel
  );

  async function obterDetalhe(): Promise<void> {
    setLoading(true);

    api
      .get<ServiceResult<TransacaoPixModel>>(`/pix/${pixId}`)
      .then(({ data: result }) => {
        setDetalhes(result.data as TransacaoPixModel);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    obterDetalhe();
  }, []);

  return (
    <MainLayout>
      <div className="d-flex align-items-center justify-content-between">
        <Link
          to="/pix"
          className="d-flex align-items-center mb-4 text-decoration-none"
        >
          <FaChevronLeft className="text-black" />
          <span className="text-uppercase fw-bold text-black  mx-3">
            Voltar
          </span>
        </Link>
        <Link to={""} className="btn border">
          <span className="semibold-16">Compartilhar</span>
          <VscExport className="mx-2" />
        </Link>
      </div>

      <div className="row mb-2">
        <div className="col-sm-12 ps-3 my-2">
          <p className="h1">Detalhes</p>
        </div>
      </div>

      {loading && <div className="text-center">Carregando...</div>}

      {!loading && (
        <div
          className="bg-white border rounded"
          
        >
          <div
            className="pt-3 pb-2 ps-3 border"
          >
            <p className="p-0 h2">Dados do Pix</p>
          </div>

          <div className="d-flex justify-content-start ps-3 py-4">
            <div className="col-lg-6">
              <div className="mb-3">
                <p className="mb-1 semibold-16">Beneficiário</p>
                <p className="m-0 regular-16">
                  {detalhes.contaDestinoTitularNome}
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-1 semibold-16">CPF/CNPJ</p>
                <p className="m-0 regular-16">
                  {detalhes.contaDestinoTitularDocumento}
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-1 semibold-16">Banco</p>
                <p className="m-0 regular-16">
                  {detalhes.contaDestinoBancoNome}
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-1 semibold-16">Agência</p>
                <p className="m-0 regular-16">{detalhes.contaDestinoAgencia}</p>
              </div>
              <div className="mb-3">
                <p className="mb-1 semibold-16">Conta Corrente</p>
                <p className="m-0 regular-16">{detalhes.contaDestinoNumero}</p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mb-3">
                <p className="mb-1 semibold-16">Data</p>
                <p className="m-0 regular-16">
                  {moment(detalhes.criadoEm)
                    .add(-3, "hours")
                    .format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-1 semibold-16">Valor</p>
                <p className="m-0 regular-16">
                  {convertCentsToCurrencyString(detalhes.valorCentavos)}
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-1 semibold-16">Chave Pix</p>
                <p className="m-0 regular-16">{detalhes.chave}</p>
              </div>
              <div className="mb-3">
                <p className="mb-1 semibold-16">Situação</p>
                <p className="m-0 regular-16">{detalhes.situacao}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
}
