import { FaShieldHalved } from "react-icons/fa6";
import { FaBarcode } from "react-icons/fa";
import { InserirCodigo } from "./components/inserir-codigo";
import { Resumo } from "./components/resumo";
import { Inclusao } from "./components/inclusao";
import { Steps } from "../../components/Steps";
import MainLayout from "../../components/Layouts/main";
import { usePagarContaStore } from "../../stores/pagar-conta-store";
import { IoNewspaperOutline } from "react-icons/io5";

export default function PagarConta() {
  const { step } = usePagarContaStore();

  return (
    <MainLayout>
      <div className="d-flex flex-column mb-4">
        <p className="h2 m-0">Pagar conta</p>
      </div>

      <Steps
        currentStep={step}
        items={[
          { icon: FaBarcode, text: "INSIRA O CÓDIGO" },
          { icon: IoNewspaperOutline, text: "RESUMO" },
          { icon: FaShieldHalved, text: "INCLUSÃO" },
        ]}
      />
      <div className="bg-white border rounded-bottom d-flex flex-column mb-2">
        {step === 1 && <InserirCodigo />}
        {step === 2 && <Resumo />}
        {step === 3 && <Inclusao />}
      </div>
    </MainLayout>
  );
}
