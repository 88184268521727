/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import ReactInputMask from "react-input-mask";
import React, { ComponentPropsWithoutRef } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form";

interface Props extends ComponentPropsWithoutRef<"input"> {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  validation: RegisterOptions;
  label?: string;
  mask?: string;
  maskChar?: string;
  type?: "text" | "password" | "email" | "date";
  icon?: React.ReactNode;
}

const Input: React.FC<Props> = ({
  control,
  errors,
  name,
  validation,
  className = "",
  label,
  mask = "",
  maskChar = "",
  type = "text",
  icon,
  ...rest
}) => {
  return (
    <>
      {label && (
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        rules={validation}
        render={({ field }) => (
          <div className="d-flex align-items-center justify-content-between">
            <ReactInputMask
              type={type}
              mask={mask}
              maskChar={maskChar}
              className={clsx(
                `form-control ${className}`,
                errors[name] && "is-invalid border-danger",
              )}
              {...rest}
              value={field.value}
              onChange={field.onChange}
              // onBlur={field.onBlur}
            />
            {icon && (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ width: 40 }}
              >
                {icon}
              </div>
            )}
          </div>
        )}
      />

      {errors[name] && (
        <p className="text-danger m-0">{errors[name]?.message?.toString()}</p>
      )}
    </>
  );
};

export default Input;
