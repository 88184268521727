import { create } from "zustand";
import PropostaDadosEmpresaArgs from "../interfaces/args/proposta-dados-empresa.args";
import PropostaDadosPessoaisArgs from "../interfaces/args/proposta-dados-pessoais.args";
import PropostaSocioModel from "../interfaces/models/proposta-socio.model";

type CadastroPJStore = {
  step: "dados-pessoais" | "dados-empresa" | "adicione-socios" | "documentos";
  setStep: (
    step: "dados-pessoais" | "dados-empresa" | "adicione-socios" | "documentos",
  ) => void;
  dadosPessoais: PropostaDadosPessoaisArgs;
  setDadosPessoais: (args: PropostaDadosPessoaisArgs) => void;
  dadosEmpresa: PropostaDadosEmpresaArgs;
  setDadosEmpresa: (args: PropostaDadosEmpresaArgs) => void;
  socios: PropostaSocioModel[];
  setSocios: (s: PropostaSocioModel[]) => void;

  // aux
  socioFotoDocumentoFile: File | null;
  socioSelfieDocumentoFile: File | null;
  socioAtosConstitutivosFile: File | null;
  atosConstitutivosFile: File | null;
};

export const useCadastroPJStore = create<CadastroPJStore>()((set) => ({
  step: "dados-pessoais",
  setStep: (step) => {
    set((state) => {
      return {
        ...state,
        step,
      };
    });
  },
  dadosPessoais: {} as PropostaDadosPessoaisArgs,
  setDadosPessoais: (args: PropostaDadosPessoaisArgs) => {
    set((state) => {
      return {
        ...state,
        dadosPessoais: args,
      };
    });
  },
  dadosEmpresa: {} as PropostaDadosEmpresaArgs,
  setDadosEmpresa: (args: PropostaDadosEmpresaArgs) => {
    set((state) => {
      return {
        ...state,
        dadosEmpresa: args,
      };
    });
  },
  socios: [],
  setSocios: (socios: PropostaSocioModel[]) => {
    set((state) => {
      return {
        ...state,
        socios,
      };
    });
  },
  socioFotoDocumentoFile: null,
  socioSelfieDocumentoFile: null,
  socioAtosConstitutivosFile: null,
  atosConstitutivosFile: null,
}));
