import MainLayout from "../../components/Layouts/main";
import { Cards } from "./components/cards";
import { TabelaUltimosLancamentos } from "./components/tabela-ultimos-lancamentos";

export default function PIX() {
  return (
    <MainLayout>
      <div className="d-flex flex-column mb-4">
        <p className="h1">Essa é sua área do PIX</p>
        <p className="m-0 regular-16">
          Faça, receba ou cobre transferências instantâneas a qualquer hora pelo
          nosso site ou app.
        </p>
      </div>

      <Cards />

      <TabelaUltimosLancamentos />
    </MainLayout>
  );
}
