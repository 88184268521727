enum TransacaoSituacao {
  // Operation is approved for banking and waiting for process
  Aprovada = "Aprovada",
  // Operation is canceled on internal server
  Cancelada = "Cancelada",
  // Success, if cash in, money available on balance... If cash out, bank send money for destination account
  Concluida = "Concluida",
  // Operation is created on internal server and waiting for banking process
  Criada = "Criada",

  // [EnumMember]
  // DevolucaoConcluida, // Refund is completed and accepted
  // [EnumMember]
  // DevolucaoFalhou, // Refund fails
  // [EnumMember]
  // DevolucaoPendente, // Refund is approved and waiting for process

  // ERROR status, has an error on internal server or banking requests
  Erro = "Erro",
  // ERROR status, operation is timed out, exemple: PIX expired
  Expirada = "Expirada",
  // ERROR status, operation is reject by internal server or banking server
  Rejeitada = "Rejeitada",
  // ERROR bank delete the pix key or qrcode key
  RemovidaBanco = "RemovidaBanco",
  // ERROR status, user delete the pix key or qrcode key
  RemovidaUsuario = "RemovidaUsuario",
  // The operation is created before but is refused later...
  Reprovada = "Reprovada",
}

export default TransacaoSituacao;
