export const IconProfile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        stroke="#131e2e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        data-name="Grupo 10993"
        transform="translate(-720 -10917.342)"
      >
        <path
          d="M737.763 10936.252l-.35-.879a3.224 3.224 0 00-.8-1.172l-.064-.06a3 3 0 00-2.039-.8h-5.013a3 3 0 00-2.039.8l-.064.06a3.212 3.212 0 00-.8 1.172l-.35.879"
          data-name="Caminho 3241"
        ></path>
        <circle
          cx="3.5"
          cy="3.5"
          r="3.5"
          data-name="Elipse 61"
          transform="translate(728.499 10923.764)"
        ></circle>
        <circle
          cx="9"
          cy="9"
          r="9"
          data-name="Elipse 62"
          transform="translate(722.999 10920.342)"
        ></circle>
      </g>
      <path fill="none" d="M0 0H24V24H0z" data-name="Retângulo 3928"></path>
    </svg>
  );
};
