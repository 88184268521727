export const IconExtract = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      data-name="Grupo 16356"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="1"
            d="M0 0H24V24H0z"
            data-name="Retângulo 4516"
          ></path>
        </clipPath>
      </defs>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4 0L0 0"
        data-name="Linha 134"
        transform="translate(12 7)"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4 0L0 0"
        data-name="Linha 135"
        transform="translate(12 11)"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4 0L0 0"
        data-name="Linha 136"
        transform="translate(12 15)"
      ></path>
      <g data-name="Grupo 16355">
        <g
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          clipPath="url(#clip-path)"
          data-name="Grupo 16354"
        >
          <path
            d="M4 20.191V4a1 1 0 011-1h14a1 1 0 011 1v16.191a.5.5 0 01-.724.447l-1.943-.971L14.667 21 12 19.667 9.333 21l-2.666-1.333-1.943.971A.5.5 0 014 20.191z"
            data-name="Caminho 22758"
          ></path>
          <path
            d="M8 6.99h-.005L8 7l.005-.005L8 6.99"
            data-name="Caminho 22759"
          ></path>
          <path
            d="M8 11l-.005.005.005.005.005-.005L8 11"
            data-name="Caminho 22760"
          ></path>
          <path
            d="M8 14.99l-.005.005L8 15l.005-.005L8 14.99"
            data-name="Caminho 22761"
          ></path>
        </g>
      </g>
    </svg>
  );
}