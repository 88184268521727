/**
 * Format currency
 * @param {number} value
 * @returns {string}
 * @example
 * convertCentsToCurrencyString(120039) // => "1.200,39"
 */
export const convertCentsToCurrencyString = (value: number): string => {
  return (value / 100).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};
