import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import ConfirmSMS from "../../../../components/confirm-sms";
import { useUpdateSaldo } from "../../../../hooks/useUpdateSaldo";
import PixEnviosPostArgs from "../../../../interfaces/args/pix-envios-post.args";
import api from "../../../../services/api-client";
import apiErrorHandler from "../../../../services/api-error-handler";
import { useEnvioPIXStore } from "../../../../stores/enviar-pix-store";

export default function EnviarPIXConfirmacao() {
  const navigate = useNavigate();

  const { args } = useEnvioPIXStore();

  const updateSaldo = useUpdateSaldo();

  async function confirmar(code: string): Promise<void> {
    let body: PixEnviosPostArgs = {
      ...args,
      codigoConfirmacaoSms: code,
    };

    api
      .post("/pix/envios", body)
      .then(() => {
        toast.success("PIX enviado com sucesso!");

        updateSaldo();

        navigate("/pix");
      })
      .catch((err) => apiErrorHandler(err));
  }

  return (
    <ConfirmSMS
      backAction={() => navigate("/pix/enviar")}
      handleSubmit={confirmar}
    />
  );
}
