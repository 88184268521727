import apiErrorHandler from "../services/api-error-handler";
import { getSaldo } from "../services/saldo.service";
import { useSaldoStore } from "../stores/saldo-store";

export function useUpdateSaldo() {
  const { setSaldo, setIsVisible } = useSaldoStore();

  return async function updateSaldo() {
    setIsVisible(false);

    getSaldo()
      .then(({ data: result }) => setSaldo(result.data?.saldo ?? 0))
      .catch((err) => apiErrorHandler(err))
      .finally(() => setIsVisible(true));
  };
}
