import { IconType } from "react-icons";

interface Props {
  /**
   * The current step of the progress bar
   * starts at 1
   * */
  currentStep: number;

  /**
   * The items that will be displayed on the progress bar
   */
  items: {
    icon: IconType;
    text: string;
  }[];
}

export const Steps = ({ items, currentStep }: Props) => {
  return (
    <div className="bg-white border rounded-top row p-0 m-0">
      <div className="px-4 d-flex justify-content-evenly align-items-center flex-row">
        {items.map((item, index) => (
          <div
            key={index}
            className={`px-2 py-3 d-flex flex-column justify-content-center align-items-center col-sm-${12 / items.length}`}
          >
            <item.icon style={{ fontSize: "1.4em" }} />
            <p className="step-text m-0 mt-1">{item.text}</p>
          </div>
        ))}
      </div>

      <div className="row p-0 m-0">
        <div className="progress p-0 m-0" style={{ height: 4 }}>
          <div
            className="progress-bar bg-primary"
            style={{ width: `${(100 / items.length) * currentStep}%` }}
            role="progressbar"
          ></div>
        </div>
      </div>
    </div>
  );
};
