import moment from "moment";
import { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { VscExport } from "react-icons/vsc";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../components/Layouts/main";
import TransacaoBoletoModel from "../../../interfaces/models/transacao-boleto.model";
import ServiceResult from "../../../interfaces/service-result";
import api from "../../../services/api-client";
import apiErrorHandler from "../../../services/api-error-handler";
import { convertCentsToCurrencyString } from "../../../utils/convert-cents-to-currency-string";
import convertEnumBoletoTipoToString from "../../../utils/convert-enum-boleto-tipo-to-string";

export default function DetalhesBoleto() {
  const navigate = useNavigate();

  const { boletoId } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [boleto, setBoleto] = useState<TransacaoBoletoModel>(
    {} as TransacaoBoletoModel,
  );

  async function obterBoletoPorId(): Promise<void> {
    setLoading(true);

    api
      .get<ServiceResult<TransacaoBoletoModel>>(`/boletos/${boletoId}`)
      .then(({ data: result }) =>
        setBoleto(result.data as TransacaoBoletoModel),
      )
      .catch((err) => {
        apiErrorHandler(err);

        if (err.response?.status === 404) {
          navigate("/extrato");
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    obterBoletoPorId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Link
          to="/extrato"
          className="d-flex align-items-center text-decoration-none cursor-pointer m-0 me-md-4"
        >
          <FaChevronLeft className="text-black" />
          <span className="text-uppercase fw-bold text-black ms-2">Voltar</span>
        </Link>
        <button type="button" className="btn border">
          <span className="semibold-16">Compartilhar</span>
          <VscExport className="mx-2" />
        </button>
      </div>

      <div>
        <p className="h2 mb-3">Comprovante</p>

        {loading && <div className="text-center">Carregando...</div>}

        {!loading && (
          <div className="bg-white p-4 border rounded mb-4">
            <div className="row">
              <p className="h3 mb-3">Dados do boleto</p>
              <hr />
              <div className="col-lg-6">
                <div>
                  <p className="semibold-16 m-0">Beneficiário</p>
                  <p className="regular-14">{boleto.contaDestinoTitularNome}</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">
                    {boleto.contaDestinoTitularDocumentoNome}
                  </p>
                  <p className="regular-14">
                    {boleto.contaDestinoTitularDocumento}
                  </p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Emissor</p>
                  <p className="regular-14">
                    {boleto.contaDestinoBancoNumero} -{" "}
                    {boleto.contaDestinoBancoNome}
                  </p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Agência</p>
                  <p className="regular-14">{boleto.contaDestinoAgencia}</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Vencimento</p>
                  <p className="regular-14">
                    {moment(boleto.vencimento)
                      .add(-3, "hours")
                      .format("DD/MM/YYYY")}
                  </p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Descrição</p>
                  <p className="regular-14">{boleto.descricao || "-"}</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Pagador</p>
                  <p className="regular-14">
                    {boleto.contaDestinoTitularNome || "-"}
                  </p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Documento do pagador</p>
                  <p className="regular-14">
                    {boleto.contaDestinoTitularDocumento || "-"}
                  </p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Natureza</p>
                  <p className="regular-14">{boleto.pagadorNatureza}</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Valor pago</p>
                  <p className="regular-14">
                    {convertCentsToCurrencyString(
                      boleto.valorPagoCentavos ?? 0,
                    )}
                  </p>
                </div>
              </div>

              <hr className="d-block d-lg-none" />

              <div className="col-lg-6">
                <div>
                  <p className="semibold-16 m-0">Tipo do boleto</p>
                  <p className="regular-14">
                    {convertEnumBoletoTipoToString(boleto.boletoTipo)}
                  </p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Última atualização</p>
                  <p className="regular-14">
                    {moment(boleto.ultimaAtualizacaoEm)
                      .add(-3, "hours")
                      .format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Situação</p>
                  <p className="regular-14">{boleto.situacao}</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Detalhes da situação</p>
                  <p className="regular-14">{boleto.situacaoDetalhe ?? "-"}</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Valor do documento</p>
                  <p className="regular-14">
                    {convertCentsToCurrencyString(boleto.valorCentavos)}
                  </p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Mora</p>
                  <p className="regular-14">{boleto.moraTaxa ?? 0}%</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Tipo de mora</p>
                  <p className="regular-14">{boleto.moraTipo ?? "-"}</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Desconto</p>
                  <p className="regular-14">
                    {convertCentsToCurrencyString(boleto.descontoValor ?? 0)}
                  </p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Tipo de desconto</p>
                  <p className="regular-14">{boleto.descontoTipo ?? "-"}</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Juros</p>
                  <p className="regular-14">{boleto.multaTaxa ?? 0}%</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Código de autenticação</p>
                  <p className="regular-14">{boleto.autenticacao}</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Código de barras</p>
                  <p className="regular-14">{boleto.codigoBarras}</p>
                </div>
                <div>
                  <p className="semibold-16 m-0">Linha digitável</p>
                  <p className="regular-14">{boleto.linhaDigitavel}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
}
