export const IconTransfer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.501"
      height="16.121"
      viewBox="0 0 15.501 16.121"
    >
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        data-name="Grupo 16352"
        transform="translate(-4.25 -3.939)"
      >
        <path d="M16.1 10.793L19 7.9 16.1 5" data-name="Caminho 22756"></path>
        <path
          d="M0 0L14 0"
          data-name="Linha 132"
          transform="translate(5 7.896)"
        ></path>
        <path d="M7.9 13.207L5 16.1 7.9 19" data-name="Caminho 22757"></path>
        <path
          d="M14 0L0 0"
          data-name="Linha 133"
          transform="translate(5 16.104)"
        ></path>
      </g>
    </svg>
  );
}