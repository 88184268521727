import Header from "../Header";
import Sidebar from "../Sidebar";

interface Props {
  children: React.ReactNode;
}

export default function MainLayout({ children }: Props) {
  return (
    <div className="row g-0">
      <div className="col-md-3 col-xl-2 bg-sidebar">
        <Sidebar />
      </div>

      <div className="col-md-9 col-xl-10">
        <Header />
        <div className="p-3 p-md-4">{children}</div>
      </div>
    </div>
  );
}
