import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { useUpdateSaldo } from "../../../hooks/useUpdateSaldo";
import apiErrorHandler from "../../../services/api-error-handler";
import api from "../../../services/api-client";
import { usePagarContaStore } from "../../../stores/pagar-conta-store";
import ConfirmSMS from "../../../components/confirm-sms";
import somenteNumeros from "../../../utils/somente-numeros";

export default function PagarContaConfirmacao() {
  const navigate = useNavigate();

  const { codigoBarras } = usePagarContaStore();

  const updateSaldo = useUpdateSaldo();

  async function confirmar(code: string): Promise<void> {
    let body: any = {
      // TODO = Alterar o body
      codigoConfirmacaoSms: code,
    };

    api
      .post(`/pagamentos/${somenteNumeros(codigoBarras)}/pagar`, body)
      .then(() => {
        toast.success("Pagamento efetuado com sucesso!");

        updateSaldo();

        navigate("/extrato");
      })
      .catch((err) => apiErrorHandler(err));
  }

  return (
    <ConfirmSMS
      backAction={() => navigate("/pagar-conta")}
      handleSubmit={confirmar}
    />
  );
}
