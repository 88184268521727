export const IconLogout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0H24V24H0z" data-name="Retângulo 3925"></path>
      <g
        fill="none"
        stroke="#131e2e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        data-name="Grupo 10989"
        transform="translate(-331.189 -1959.73)"
      >
        <path
          d="M0 0L10.135 0"
          data-name="Linha 93"
          transform="translate(341.055 1971.731)"
        ></path>
        <path
          d="M342.053 1979.712l-4.168.019a2.146 2.146 0 01-2.167-2.134v-11.731a2.146 2.146 0 012.157-2.135h4.314"
          data-name="Caminho 3237"
        ></path>
        <path d="M347.189 1975.731l4-4-4-4" data-name="Caminho 3238"></path>
      </g>
      <path fill="none" d="M0 0H24V24H0z" data-name="Retângulo 3926"></path>
    </svg>
  );
};
