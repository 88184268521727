import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { FaFileUpload } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ServiceResult from "../../../../interfaces/service-result";
import api from "../../../../services/api-client";
import apiErrorHandler from "../../../../services/api-error-handler";
import { useCadastroPJStore } from "../../../../stores/cadastro-pj-store";

export const Documentos = () => {
  const propostaId: string = localStorage.getItem("propostaId") ?? "";

  const navigate = useNavigate();

  const { setStep, atosConstitutivosFile } = useCadastroPJStore();

  const [loading, setLoading] = useState<boolean>(false);

  const atosConstitutivosRef = useRef<HTMLInputElement | null>(null);

  const { handleSubmit } = useForm();

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];

    if (!file) return;

    useCadastroPJStore.setState({
      atosConstitutivosFile: file,
    });
  }

  async function onSubmit(): Promise<void> {
    if (!atosConstitutivosFile) {
      toast.error(
        <p className="m-0">
          Envie o arquivo: <strong>Atos constitutivos</strong> da sua empresa
          para continuar.
        </p>,
      );
      return;
    }

    setLoading(true);

    let formData = new FormData();
    formData.append(
      "ArquivoAtosConstitutivos",
      atosConstitutivosFile!,
      atosConstitutivosFile!.name,
    );

    api
      .putForm<ServiceResult<string>>(`/propostas/${propostaId}`, formData)
      .then(() => {
        useCadastroPJStore.setState({
          atosConstitutivosFile: null,
        });

        navigate("/login");

        toast.success(
          <p className="m-0">
            Sua proposta de cadastro foi enviada com sucesso!
            <br />
            Assim que aprovado você receberá um e-mail para definir uma senha de
            acesso a sua conta.
          </p>,
          { duration: 10000 },
        );
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-lg-5 mb-5 pb-4">
        <div className="row m-3">
          <p className="h1 mb-4">Adicione a documentação</p>
          <p className="h2 mb-3">Escolher arquivos</p>
        </div>

        <div className="row m-3">
          <div className="col-lg-12 mb-3">
            <label className="form-label">Atos constitutivos</label>

            {/* Input file hidden */}
            <input
              ref={atosConstitutivosRef}
              style={{ display: "none" }}
              type="file"
              accept="*"
              multiple={false}
              onChange={handleFileUpload}
            />

            <button
              type="button"
              className="btn bg-white w-100 d-flex align-items-center justify-content-center"
              style={{
                border: "2px solid #d2d6dc",
                height: 54,
              }}
              onClick={() => {
                if (loading) return;
                atosConstitutivosRef.current?.click();
              }}
            >
              <FaFileUpload className="me-2" style={{ color: "#777777" }} />
              <p className="medium-16 m-0" style={{ color: "#777777" }}>
                Escolher arquivo
              </p>
            </button>

            {atosConstitutivosFile && (
              <div className="max-w-100 text-break mt-2 d-inline-flex justify-content-between align-items-center border border-dark rounded p-2">
                <p className="m-0">{atosConstitutivosFile.name}</p>
                <IoClose
                  size={20}
                  className="ms-2 cursor-pointer text-danger"
                  onClick={() => {
                    useCadastroPJStore.setState({
                      atosConstitutivosFile: null,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* WEB */}
      <div
        className={
          "position-fixed bottom-0 bg-white w-50 d-none d-lg-flex justify-content-between align-items-center p-3"
        }
        style={{ borderTop: "1px solid #E5E5E5" }}
      >
        <button
          type="button"
          className="btn d-flex align-items-center"
          onClick={() => setStep("adicione-socios")}
        >
          <FaChevronLeft className="me-2" />
          <p className="semibold-14 m-0 text-uppercase">Voltar</p>
        </button>

        <button type="submit" disabled={loading} className="btn btn-primary">
          {loading ? "Enviando..." : "Criar conta"}
        </button>
      </div>

      {/* MOBILE */}
      <div
        className={
          "position-fixed bottom-0 bg-white w-100 d-flex d-lg-none justify-content-between align-items-center p-3"
        }
        style={{ borderTop: "1px solid #E5E5E5" }}
      >
        <button
          type="button"
          className="btn d-flex align-items-center"
          onClick={() => setStep("adicione-socios")}
        >
          <FaChevronLeft className="me-2" />
          <p className="semibold-14 m-0 text-uppercase">Voltar</p>
        </button>

        <button type="submit" disabled={loading} className="btn btn-primary">
          {loading ? "Enviando..." : "Criar conta"}
        </button>
      </div>
    </form>
  );
};
