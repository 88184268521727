import clsx from "clsx";
import { FaChevronDown } from "react-icons/fa6";
import { useSearchParams } from "react-router-dom";

interface Props {
  numeroPaginas: number;
}

export const TablePagination = ({ numeroPaginas }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  function handlePageChange(pagina: number): void {
    setSearchParams((state) => {
      state.set("page", pagina.toString());

      return state;
    });
  }

  function handlePageSizeChange(paginaTamanho: number): void {
    setSearchParams((state) => {
      state.set("page", "1");
      state.set("size", paginaTamanho.toString());

      return state;
    });
  }

  return (
    <div className="my-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center p-0">
      {/* Quantidade de exibição */}
      <div className="p-0 mb-2 mb-md-0">
        <div
          className="d-flex cursor-pointer justify-content-center p-2 bg-primary rounded"
          data-bs-toggle="dropdown"
        >
          <div className="d-flex flex-column justify-content-between dropdown cursor-pointer">
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 semibold-14">
                Exibir de {paginaTamanho} em {paginaTamanho}
              </p>

              <div className="dropdown ps-2">
                <FaChevronDown />
              </div>
              <ul
                id="dropdown"
                className="dropdown-menu m-0"
                style={{ width: 260 }}
              >
                <li>
                  <button
                    onClick={() => {
                      if (paginaTamanho === 5) return;

                      handlePageSizeChange(5);
                    }}
                    className={clsx(
                      "dropdown-item d-flex align-items-center",
                      paginaTamanho === 5 && "active",
                    )}
                  >
                    Exibir de 5 em 5
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      if (paginaTamanho === 10) return;

                      handlePageSizeChange(10);
                    }}
                    className={clsx(
                      "dropdown-item d-flex align-items-center",
                      paginaTamanho === 10 && "active",
                    )}
                  >
                    Exibir de 10 em 10
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      if (paginaTamanho === 15) return;

                      handlePageSizeChange(15);
                    }}
                    className={clsx(
                      "dropdown-item d-flex align-items-center",
                      paginaTamanho === 15 && "active",
                    )}
                  >
                    Exibir de 15 em 15
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      if (paginaTamanho === 20) return;

                      handlePageSizeChange(20);
                    }}
                    className={clsx(
                      "dropdown-item d-flex align-items-center",
                      paginaTamanho === 20 && "active",
                    )}
                  >
                    Exibir de 20 em 20
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Navegação por pagina */}
      {numeroPaginas > 1 && (
        <nav aria-label="...">
          <ul className="pagination m-0">
            {/* Página anterior */}
            {pagina !== 1 && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina - 1)}
                >
                  Anterior
                </p>
              </li>
            )}

            {/* Continuação de página anterior */}
            {pagina > 3 && (
              <li className="page-item cursor-pointer">
                <p className="m-0 page-link">...</p>
              </li>
            )}

            {/* Página atual -2 */}
            {pagina - 1 > 1 && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina - 2)}
                >
                  {pagina - 2}
                </p>
              </li>
            )}

            {/* Página atual -1 */}
            {pagina > 1 && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina - 1)}
                >
                  {pagina - 1}
                </p>
              </li>
            )}

            {/* Página atual */}
            <li className="page-item cursor-pointer active">
              <p className="m-0 page-link">{pagina}</p>
            </li>

            {/* Pagina atual +1 */}
            {pagina < numeroPaginas && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina + 1)}
                >
                  {pagina + 1}
                </p>
              </li>
            )}

            {/* Pagina atual +2 */}
            {pagina + 1 < numeroPaginas && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina + 2)}
                >
                  {pagina + 2}
                </p>
              </li>
            )}

            {/* Continuação de próxima página */}
            {numeroPaginas - pagina > 2 && (
              <li className="page-item cursor-pointer">
                <p className="m-0 page-link">...</p>
              </li>
            )}

            {/* Próxima página */}
            {/* if (PaginaAtual != NumeroPaginas) */}
            {pagina !== numeroPaginas && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(pagina + 1)}
                >
                  Próxima
                </p>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
};
