import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CadastroPJ from "../pages/cadastro/pj";
import Home from "../pages/home";
import Login from "../pages/login";
import PrivateRoute from "./private-route";
import CadastroPF from "../pages/cadastro/pf";
import PIX from "../pages/pix";
import EnviarPIX from "../pages/pix/enviar";
import CobrarPIX from "../pages/pix/cobrar";
import ExtratoPIX from "../pages/pix/extrato";
import Extrato from "../pages/extrato";
import Perfil from "../pages/perfil";
import RecuperarSenha from "../pages/recuperar-senha";
import RecuperarSenhaToken from "../pages/recuperar-senha/token";
import CobrarPIXQRCode from "../pages/pix/cobrar/qrcode";
import EnviarPIXConfirmacao from "../pages/pix/enviar/confirmacao";
import DetalhesPix from "../pages/extrato/detalhes/pix";
import DetalhesBoleto from "../pages/extrato/detalhes/boleto";
import PagarConta from "../pages/pagar-conta";
import PagarContaConfirmacao from "../pages/pagar-conta/confirmacao";
import Boleto from "../pages/boleto";
import Dev from "../pages/dev";
import EmitirBoleto from "../pages/boleto/EmitirBoleto";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        {/* rotas publicas */}
        <Route path="/login" element={<Login />} />
        <Route path="/cadastro/pf" element={<CadastroPF />} />
        <Route path="/cadastro/pj" element={<CadastroPJ />} />
        <Route path="/recuperar-senha" element={<RecuperarSenha />} />
        <Route
          path="/recuperar-senha/:token"
          element={<RecuperarSenhaToken />}
        />

        {/* rotas privadas */}
        <Route
          path="/pix/enviar/confirmacao"
          element={<EnviarPIXConfirmacao />}
        />
        <Route path="/pix/cobrar" element={<CobrarPIX />} />
        <Route path="/pix/cobrar/qrcode" element={<CobrarPIXQRCode />} />
        <Route path="/pix/extrato" element={<ExtratoPIX />} />

        <Route path="/pagar-conta" element={<PagarConta />} />
        <Route
          path="/pagar-conta/confirmacao"
          element={<PagarContaConfirmacao />}
        />

        <Route path="/perfil" element={<Perfil />} />

        {/* Private route test */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />

        <Route
          path="/extrato"
          element={
            <PrivateRoute>
              <Extrato />
            </PrivateRoute>
          }
        />

        <Route
          path="/extrato/pix/:pixId"
          element={
            <PrivateRoute>
              <DetalhesPix />
            </PrivateRoute>
          }
        />

        <Route
          path="/extrato/boleto/:boletoId"
          element={
            <PrivateRoute>
              <DetalhesBoleto />
            </PrivateRoute>
          }
        />

        <Route
          path="/boletos"
          element={
            <PrivateRoute>
              <Boleto />
            </PrivateRoute>
          }
        />

        <Route
          path="/boletos/emitir"
          element={
            <PrivateRoute>
              <EmitirBoleto />
            </PrivateRoute>
          }
        />

        <Route
          path="/pix"
          element={
            <PrivateRoute>
              <PIX />
            </PrivateRoute>
          }
        />

        <Route
          path="/pix/enviar"
          element={
            <PrivateRoute>
              <EnviarPIX />
            </PrivateRoute>
          }
        />

        <Route
          path="/pix/enviar/confirmacao"
          element={
            <PrivateRoute>
              <EnviarPIXConfirmacao />
            </PrivateRoute>
          }
        />

        <Route
          path="/pix/cobrar"
          element={
            <PrivateRoute>
              <CobrarPIX />
            </PrivateRoute>
          }
        />

        <Route
          path="/pix/cobrar/qrcode"
          element={
            <PrivateRoute>
              <CobrarPIXQRCode />
            </PrivateRoute>
          }
        />
        <Route
          path="/pix/cobrar"
          element={
            <PrivateRoute>
              <CobrarPIX />
            </PrivateRoute>
          }
        />

        <Route
          path="/pix/cobrar/qrcode"
          element={
            <PrivateRoute>
              <CobrarPIXQRCode />
            </PrivateRoute>
          }
        />

        <Route
          path="/pix/extrato"
          element={
            <PrivateRoute>
              <ExtratoPIX />
            </PrivateRoute>
          }
        />

        <Route
          path="/perfil"
          element={
            <PrivateRoute>
              <Perfil />
            </PrivateRoute>
          }
        />

        {/*
        <Route path="/pagar-conta" element={<PagarConta />} />
        <Route
          path="/pagar-conta/confirmacao"
          element={<PagarContaConfirmacao />}
        />
        */}

        <Route
          path="/dev"
          element={
            <PrivateRoute>
              <Dev />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}
