import { useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { usePagarContaStore } from "../../../stores/pagar-conta-store";
import InputCurrency from "../../../components/InputCurrency";
import { useState } from "react";
import { mascaraCNPJ, mascaraCPF } from "../../../utils/mascara";

interface FormValues {
  valor: string;
  descricao: string;
}

export const Resumo = () => {
  const { setStep, setConsulta, consulta } = usePagarContaStore();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    values: {
      valor: consulta.valorTotalCentavos?.toString() ?? "",
      descricao: "",
    },
  });

  async function onSubmit(data: FormValues): Promise<void> {
    setStep(3);
  }

  return (
    <>
      <p className="h2 mx-3 mx-md-5 my-4">Para quem você vai transferir?</p>

      {/* FORM */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row px-3 px-md-5">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <InputCurrency
                  control={control}
                  errors={errors}
                  name="valor"
                  label="Valor"
                  validation={{ required: "Valor é obrigatório" }}
                  disabled
                />
                {/* <label htmlFor="valor" className="form-label">
                  Valor
                </label>

                <input
                  {...register("valor", {
                    required: "Valor é obrigatório",
                  })}
                  className={clsx(
                    "form-control",
                    errors.valor && "is-invalid border-danger",
                  )}
                  placeholder="R$ 0,00"
                />

                {errors.valor && (
                  <p className="text-danger m-0">
                    {errors.valor.message?.toString()}
                  </p>
                )} */}
              </div>

              {/*<div className="col-lg-6 mb-3">
                <label htmlFor="data" className="form-label">
                  Data de transferência
                </label>

                <input
                  {...register("data")}
                  disabled
                  type="text"
                  className="form-control disabled"
                  placeholder="XX/XX/XXXX"
                />
              </div>*/}

              <div className="col-lg-12 mb-3">
                <label htmlFor="descricao" className="form-label">
                  Descrição (opcional)
                </label>

                <input
                  {...register("descricao")}
                  disabled
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="border rounded bg-light p-3 p-md-4">
              <p className="h5 mb-3">Dados do beneficiário</p>
              <div className="d-flex align-items-center">
                <p className="h6 mb-3">Nome: </p>
                <p className="h6 mb-3">
                  <span className="ms-1 fw-bold text-break">
                    {consulta.beneficiarioNome}
                  </span>
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="h6">Documento: </p>
                <p className="h6">
                  <span className="ms-1 fw-bold text-break">
                    {consulta.beneficiarioDocumento}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* FORM BUTTONS */}
        <div className="p-3 p-md-4 d-flex flex-md-row justify-content-between justify-content-md-end align-items-center">
          <p
            onClick={() => setStep(1)}
            className="d-flex align-items-center text-decoration-none cursor-pointer m-0 me-md-4"
          >
            <FaChevronLeft className="text-black" />
            <span className="text-uppercase fw-bold text-black ms-2">
              Voltar
            </span>
          </p>
          <button type="submit" className="btn btn-primary">
            Avançar
          </button>
        </div>
      </form>
    </>
  );
};
