import { create } from "zustand";
import BoletosRecebimentosPostArgs from "../interfaces/args/boletos-recebimentos-post.args";

type EmissaoBoletoStore = {
  step: 1 | 2 | 3;
  setStep: (step: 1 | 2 | 3) => void;
  args: BoletosRecebimentosPostArgs;
  setArgs: (args: BoletosRecebimentosPostArgs) => void;
};

export const useEmissaoBoletoStore = create<EmissaoBoletoStore>()((set) => ({
  step: 1,
  setStep: (step) => {
    set((state) => {
      return {
        ...state,
        step,
      };
    });
  },

  args: {} as BoletosRecebimentosPostArgs,
  setArgs: (args) => {
    set((state) => {
      return {
        ...state,
        args,
      };
    });
  },
}));
