import { useForm } from "react-hook-form";
import EstadoCivil from "../../../../enums/estado-civil";
import PropostaDadosPessoaisArgs from "../../../../interfaces/args/proposta-dados-pessoais.args";
import InputMask from "react-input-mask";
import { toast } from "react-hot-toast";
import Input from "../../../../components/Input";
import clsx from "clsx";
import somenteNumeros from "../../../../utils/somente-numeros";
import { useCadastroPFStore } from "../../../../stores/cadastro-pf-store";

export const DadosPessoais = () => {
  const { dadosPessoais, setDadosPessoais, setStep } = useCadastroPFStore();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<PropostaDadosPessoaisArgs>({
    values: dadosPessoais,
  });

  async function buscarCep(e: React.FocusEvent<HTMLInputElement>) {
    const cep = somenteNumeros(e.target.value);

    if (cep.length !== 8) {
      return;
    }

    setValue("endereco.lougradouro", "...");
    setValue("endereco.bairro", "...");
    setValue("endereco.cidade", "...");
    setValue("endereco.estado", "...");

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.erro) {
          toast.error("CEP não encontrado");
          setValue("endereco.lougradouro", "");
          setValue("endereco.bairro", "");
          setValue("endereco.cidade", "");
          setValue("endereco.estado", "");
          return;
        }

        const { logradouro, bairro, localidade, uf } = data;

        setValue("endereco.lougradouro", logradouro);
        setValue("endereco.bairro", bairro);
        setValue("endereco.cidade", localidade);
        setValue("endereco.estado", uf);
      });
  }

  async function onSubmit(data: PropostaDadosPessoaisArgs): Promise<void> {
    setDadosPessoais(data);
    setStep("documentos");
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-lg-5 mb-5 pb-4">
        <div className="row m-3">
          <p className="h1 mb-4">Inicie seu cadastro</p>
          <p className="h2 mb-3">Dados pessoais</p>
        </div>

        <div className="row m-3">
          <div className="col-lg-12 mb-3">
            <Input
              type="text"
              control={control}
              errors={errors}
              label="Nome completo"
              name="nomeCompleto"
              placeholder="Nome completo"
              validation={{ required: "Nome completo é obrigatório" }}
            />
          </div>

          <div className="col-lg-12 mb-3">
            <Input
              control={control}
              errors={errors}
              label="E-mail"
              name="email"
              type="email"
              placeholder="E-mail"
              validation={{ required: "E-mail é obrigatório" }}
            />
          </div>

          <div className="col-lg-12 mb-3">
            <Input
              control={control}
              errors={errors}
              label="Telefone de contato"
              name="telefoneContato"
              mask="(99) 99999-9999"
              maskChar="X"
              type="text"
              placeholder="(XX) XXXXX-XXXX"
              validation={{ required: "Telefone de contato é obrigatório" }}
            />
          </div>

          <div className="col-lg-6 mb-3">
            <Input
              control={control}
              errors={errors}
              label="CPF"
              name="cpf"
              mask="999.999.999-99"
              maskChar="X"
              type="text"
              placeholder="Insira seu CPF"
              validation={{ required: "CPF é obrigatório" }}
            />
          </div>

          <div className="col-lg-6 mb-3">
            <Input
              control={control}
              errors={errors}
              label="Data de nascimento"
              mask="99/99/9999"
              maskChar="X"
              name="dataNascimento"
              type="text"
              placeholder="XX/XX/XXXX"
              validation={{ required: "Data de nascimento é obrigatória" }}
            />
          </div>

          <div className="col-lg-6 mb-3">
            <Input
              control={control}
              errors={errors}
              label="RG"
              name="rg"
              type="text"
              placeholder="Insira seu RG"
              validation={{ required: "RG é obrigatório" }}
            />
          </div>

          <div className="col-lg-6 mb-3">
            <Input
              control={control}
              errors={errors}
              label="UF do Documento"
              name="rgUf"
              type="text"
              placeholder="UF"
              className="text-uppercase"
              maxLength={2}
              validation={{
                required: "UF do documento é obrigatório",
                // 2 characters & only letters
                pattern: {
                  value: /^[a-zA-Z]{2}$/,
                  message: "UF inválida",
                },
              }}
            />
          </div>

          {/* TODO: ABSTRAIR PARA COMPONENTE SELECT */}
          <div className="col-lg-6 mb-3">
            <label htmlFor="estadoCivil" className="form-label">
              Estado Civil
            </label>

            <select
              {...register("estadoCivil", {
                required: "Estado civil é obrigatório",
              })}
              defaultValue=""
              className={clsx(
                "form-select",
                errors.estadoCivil && "is-invalid border-danger",
              )}
            >
              <option value="" disabled>
                Selecione
              </option>
              {Object.entries(EstadoCivil).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>

            {errors.estadoCivil && (
              <p className="text-danger m-0">{errors.estadoCivil.message}</p>
            )}
          </div>

          {/* TODO: ABSTRAIR PARA COMPONENTE SELECT */}
          <div className="col-lg-6 mb-3">
            <label htmlFor="nacionalidade" className="form-label">
              Nacionalidade
            </label>

            <select
              {...register("nacionalidade", {
                required: "Nacionalidade é obrigatória",
              })}
              defaultValue=""
              className={clsx(
                "form-select",
                errors.nacionalidade && "is-invalid border-danger",
              )}
            >
              <option value="" disabled>
                Selecione
              </option>
              <option value="brasileira">Brasileira</option>
            </select>

            {errors.nacionalidade && (
              <p className="text-danger m-0">{errors.nacionalidade.message}</p>
            )}
          </div>
        </div>

        <div className="row m-3">
          <p className="h2 mb-3">Informações de endereço</p>
        </div>

        <div className="row m-3">
          <div className="col-lg-12 mb-3">
            <label htmlFor="endereco.cep" className="form-label">
              CEP
            </label>

            <InputMask
              {...register("endereco.cep", {
                required: "CEP é obrigatório",
                pattern: {
                  value: /^\d{5}-\d{3}$/,
                  message: "CEP inválido",
                },
              })}
              className={clsx(
                "form-control",
                errors.endereco?.cep && "is-invalid border-danger",
              )}
              mask="99999-999"
              maskChar="_"
              type="text"
              placeholder="_____-___"
              onBlur={buscarCep}
            />

            {errors.endereco?.cep && (
              <p className="text-danger m-0">{errors.endereco.cep.message}</p>
            )}
          </div>

          <div className="col-lg-12 mb-3">
            <label htmlFor="endereco.lougradouro" className="form-label">
              Rua
            </label>

            <input
              {...register("endereco.lougradouro", {
                required: "Logradouro é obrigatório",
              })}
              type="text"
              className={clsx(
                "form-control",
                errors.endereco?.lougradouro && "is-invalid border-danger",
              )}
              placeholder="Logradouro"
            />

            {errors.endereco?.lougradouro && (
              <p className="text-danger m-0">
                {errors.endereco.lougradouro.message}
              </p>
            )}
          </div>

          <div className="col-lg-6 mb-3">
            <label htmlFor="endereco.numero" className="form-label">
              Número
            </label>
            <input
              {...register("endereco.numero")}
              type="text"
              className="form-control"
              placeholder="S/N ou número"
            />
          </div>

          <div className="col-lg-6 mb-3">
            <label htmlFor="endereco.complemento" className="form-label">
              Complemento
            </label>
            <input
              {...register("endereco.complemento")}
              type="text"
              className="form-control"
              placeholder="Apto, casa..."
            />
          </div>

          <div className="col-lg-12 mb-3">
            <label htmlFor="endereco.bairro" className="form-label">
              Bairro
            </label>

            <input
              {...register("endereco.bairro", {
                required: "Bairro é obrigatório",
              })}
              type="text"
              className={clsx(
                "form-control",
                errors.endereco?.bairro && "is-invalid border-danger",
              )}
              placeholder="Bairro"
            />

            {errors.endereco?.bairro && (
              <p className="text-danger m-0">
                {errors.endereco.bairro.message}
              </p>
            )}
          </div>

          <div className="col-lg-12 mb-3">
            <label htmlFor="endereco.estado" className="form-label">
              Estado
            </label>

            <input
              {...register("endereco.estado", {
                required: "Estado é obrigatório",
              })}
              type="text"
              className={clsx(
                "form-control",
                errors.endereco?.estado && "is-invalid border-danger",
              )}
              placeholder="Estado"
            />

            {errors.endereco?.estado && (
              <p className="text-danger m-0">
                {errors.endereco.estado.message}
              </p>
            )}
          </div>

          <div className="col-lg-12 mb-3">
            <label htmlFor="endereco.cidade" className="form-label">
              Cidade
            </label>

            <input
              {...register("endereco.cidade", {
                required: "Cidade é obrigatória",
              })}
              type="text"
              className={clsx(
                "form-control",
                errors.endereco?.cidade && "is-invalid border-danger",
              )}
              placeholder="Cidade"
            />

            {errors.endereco?.cidade && (
              <p className="text-danger m-0">
                {errors.endereco.cidade.message}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* WEB */}
      <div
        className={
          "position-fixed bottom-0 bg-white w-50 d-none d-lg-flex justify-content-end align-items-center p-3"
        }
        style={{ borderTop: "1px solid #E5E5E5" }}
      >
        <button type="submit" className="btn btn-primary">
          Avançar
        </button>
      </div>

      {/* MOBILE */}
      <div
        className={
          "position-fixed bottom-0 bg-white w-100 d-flex d-lg-none justify-content-end align-items-center p-3"
        }
        style={{ borderTop: "1px solid #E5E5E5" }}
      >
        <button type="submit" className="btn btn-primary">
          Avançar
        </button>
      </div>
    </form>
  );
};
