import clsx from "clsx";
import { useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import PixEnviosPostArgs from "../../../../interfaces/args/pix-envios-post.args";
import { useEnvioPIXStore } from "../../../../stores/enviar-pix-store";
import somenteNumeros from "../../../../utils/somente-numeros";
import InputCurrency from "../../../../components/InputCurrency";

interface FormValues {
  valor: string;
  descricao: string;
}

export const ValorData = () => {
  const { setStep, args, setArgs } = useEnvioPIXStore();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    values: {
      valor: args.valorCentavos?.toString() || "",
      descricao: "",
    },
  });

  async function onSubmit(data: FormValues): Promise<void> {
    setArgs({
      ...args,
      valorCentavos: Number(somenteNumeros(data.valor)),
    } as PixEnviosPostArgs);

    setStep(4); // TODO: ALTERAR PARA STEP 3 QUANDO HOUVER CONSULTA
  }

  return (
    <>
      <p className="h2 mx-3 mx-md-5 my-4">Para quem você vai transferir?</p>

      {/* FORM */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row px-3 px-md-5">
          <div className="col-lg-6 mb-3">
            <InputCurrency
              control={control}
              errors={errors}
              name="valor"
              label="Valor"
              validation={{ required: "Valor é obrigatório" }}
            />
          </div>

          <div className="col-lg-12 mb-3">
            <label htmlFor="descricao" className="form-label">
              Descrição (opcional)
            </label>

            <input
              {...register("descricao")}
              type="text"
              className="form-control"
              placeholder="Insira uma descrição para a transferência"
            />
          </div>
        </div>

        {/* FORM BUTTONS */}
        <div className="p-3 p-md-4 d-flex flex-md-row justify-content-between justify-content-md-end align-items-center">
          <p
            onClick={() => setStep(1)}
            className="d-flex align-items-center text-decoration-none cursor-pointer m-0 me-md-4"
          >
            <FaChevronLeft className="text-black" />
            <span className="text-uppercase fw-bold text-black ms-2">
              Voltar
            </span>
          </p>
          <button type="submit" className="btn btn-primary">
            Avançar
          </button>
        </div>
      </form>
    </>
  );
};
