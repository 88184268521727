import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../components/Input";
import CleanLayout from "../../../components/Layouts/clean";
import EsqueciMinhaSenhaNovaSenhaArgs from "../../../interfaces/args/esqueci-minha-senha-nova-senha.args";
import api from "../../../services/api-client";
import apiErrorHandler from "../../../services/api-error-handler";

interface FormValues {
  password: string;
  confirmPassword: string;
}

export default function RecuperarSenhaToken() {
  const navigate = useNavigate();

  const { token } = useParams();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>();

  async function handleAlterarSenha(data: FormValues): Promise<void> {
    let args: EsqueciMinhaSenhaNovaSenhaArgs = {
      token: token!,
      novaSenha: data.password,
      confirmacaoNovaSenha: data.confirmPassword,
    };

    api
      .post("/auth/esqueci-minha-senha/nova-senha", args)
      .then(() => {
        toast.success("Senha alterada com sucesso!");
        navigate("/login");
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  return (
    <CleanLayout backAction={() => navigate("/login")}>
      <div className="w-100 row d-flex justify-content-center">
        <div className="col-md-6 col-lg-4">
          <form
            className="w-100 d-flex flex-column"
            onSubmit={handleSubmit(handleAlterarSenha)}
          >
            <Input
              control={control}
              errors={errors}
              validation={{ required: "Campo obrigatório" }}
              label="Senha"
              type="password"
              name="password"
            />

            <Input
              control={control}
              errors={errors}
              validation={{ required: "Campo obrigatório" }}
              label="Confirmar senha"
              type="password"
              name="confirmPassword"
            />

            <button
              className="w-100 btn btn-primary mt-4"
              disabled={loading}
              type="submit"
            >
              {loading ? "Aguarde..." : "Enviar"}
            </button>
          </form>
        </div>
      </div>
    </CleanLayout>
  );
}
