import { useEffect } from "react";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import { FaArrowsRotate } from "react-icons/fa6";
import MainLayout from "../../components/Layouts/main";
import { useUpdateSaldo } from "../../hooks/useUpdateSaldo";
import { useDadosContaStore } from "../../stores/dados-conta-store";
import { useSaldoStore } from "../../stores/saldo-store";
import { convertCentsToCurrencyString } from "../../utils/convert-cents-to-currency-string";
import { InfoConta } from "./components/info-conta";

export default function Home() {
  const { saldo, isVisible, setIsVisible } = useSaldoStore();
  const { dadosPessoais } = useDadosContaStore();

  const updateSaldo = useUpdateSaldo();

  useEffect(() => {
    updateSaldo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="row">
        <div className="col-md-8">
          <p className="h1 mb-4 text-break">
            Bem vindo, {dadosPessoais?.nomeCompleto?.split(" ")[0]}!
          </p>
          <p className="regular-16 mb-4">
            Você está acessando sua conta na plataforma de pagamentos que vai
            facilitar a sua vida. Aqui você pode pagar contas, receber,
            transferir dinheiro, e muito mais. Tudo isso com segurança e
            praticidade.
          </p>
        </div>
        <div
          className="col-md-4 p-3 d-flex flex-column justify-content-center bg-white rounded"
          style={{ border: "1px solid #e8e8e8" }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <p className="regular-16 m-0">Saldo:</p>
            <div className="d-flex justify-content-end align-items-center">
              <button
                type="button"
                onClick={updateSaldo}
                className="d-flex align-items-center border-0 p-2 bg-transparent"
              >
                <FaArrowsRotate className="fs-5 text-black" />
              </button>

              <button
                type="button"
                className="d-flex align-items-center border-0 p-2 bg-transparent"
                onClick={() => setIsVisible(!isVisible)}
              >
                {isVisible ? (
                  <FaRegEyeSlash className="fs-4 text-black" />
                ) : (
                  <FaEye className="fs-4 text-black" />
                )}
              </button>
            </div>
          </div>

          <p className="h2 mb-2">
            {isVisible ? convertCentsToCurrencyString(saldo) : "R$ ••••••"}
          </p>

          <hr />

          <InfoConta />
        </div>
      </div>
    </MainLayout>
  );
}
