import moment from "moment";
import { useEffect, useState } from "react";
import { FaCircleInfo, FaEllipsisVertical, FaPix } from "react-icons/fa6";
import { Link, useSearchParams } from "react-router-dom";
import { Loading } from "../../../../components/Loading";
import { NoData } from "../../../../components/NoData";
import { TablePagination } from "../../../../components/table-pagination";
import TransacaoSituacao from "../../../../enums/transacao-situacao";
import TransacaoTipo from "../../../../enums/transacao-tipo";
import ListServiceResult from "../../../../interfaces/list-service-result";
import TransacaoPixModel from "../../../../interfaces/models/transacao-pix.model";
import api from "../../../../services/api-client";
import apiErrorHandler from "../../../../services/api-error-handler";
import { convertCentsToCurrencyString } from "../../../../utils/convert-cents-to-currency-string";
import convertEnumTransacaoTipoToString from "../../../../utils/convert-enum-transacao-tipo-to-string";

export const TabelaExtrato = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transacoes, setTransacoes] = useState<TransacaoPixModel[]>([]);
  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();

  const pagina = Number(searchParams.get("page")) || 1;
  const paginaTamanho = Number(searchParams.get("size")) || 10;

  async function obterExtrato(): Promise<void> {
    setLoading(true);

    api
      .get<ListServiceResult<TransacaoPixModel>>(
        `/pix?pagina=${pagina}&paginaTamanho=${paginaTamanho}`,
      )
      .then(({ data: result }) => {
        setTransacoes(result.data || []);
        setNumeroPaginas(result.pages || 0);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    obterExtrato();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, paginaTamanho]);

  return (
    <>
      {loading && (
        <div className="row mx-0">
          <div className="col-sm-12 p-0">
            <div className="d-flex justify-content-center align-items-center">
              <Loading />
            </div>
          </div>
        </div>
      )}

      {!loading && transacoes.length > 0 && (
        <div className="row mx-0">
          <div
            className="bg-white col-sm-12 p-0"
            style={{ border: "1px solid #e8e8e8", borderRadius: 4 }}
          >
            <table className="table m-0 text-black table-responsive-stack">
              <thead>
                <tr>
                  <th scope="col" className="py-3 ps-3">
                    Lançamentos
                  </th>
                  <th scope="col" className="py-3">
                    Data
                  </th>
                  <th scope="col" className="py-3">
                    Valor/Status
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {transacoes.map((t, index) => (
                  <tr className="align-middle" key={index}>
                    <td className="py-3 ps-3 col-sm-5">
                      <div className="d-flex align-items-center">
                        <div className="px-2">
                          <FaPix style={{ fontSize: "1.7rem" }} />
                        </div>
                        <div className="d-flex flex-column">
                          <p className="m-0 medium-14">
                            {convertEnumTransacaoTipoToString(t.tipo)}
                          </p>
                          <p className="m-0 regular-14">
                            {t.contaDestinoTitularNome}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="py-3 ps-3 ps-md-0">
                      <div className="d-flex flex-column">
                        <p className="m-0 medium-14">
                          {moment(t.ultimaAtualizacaoEm)
                            .add(-3, "hours")
                            .format("DD/MM/YYYY")}
                        </p>
                        <p className="m-0 regular-14">
                          {moment(t.ultimaAtualizacaoEm)
                            .add(-3, "hours")
                            .format("HH[h]mm[m]")}
                        </p>
                      </div>
                    </td>
                    <td className="py-3 ps-3 ps-md-0">
                      {t.situacao === TransacaoSituacao.Criada ||
                      t.situacao === TransacaoSituacao.Aprovada ? (
                        <p
                          className="m-0 medium-14"
                          style={{ color: "#FF7F44" }}
                        >
                          {`${convertCentsToCurrencyString(t.valorCentavos)} (Pendente)`}
                        </p>
                      ) : (
                        <>
                          {t.tipo === TransacaoTipo.PixRecebimento ||
                          t.tipo === TransacaoTipo.PixEnvioDevolucao ? (
                            <p
                              className="m-0 medium-14"
                              style={{ color: "#30C179" }}
                            >
                              {`+ ${convertCentsToCurrencyString(t.valorCentavos)}`}
                            </p>
                          ) : (
                            <p
                              className="m-0 medium-14"
                              style={{ color: "#D32F2F" }}
                            >
                              {`- ${convertCentsToCurrencyString(t.valorCentavos)}`}
                            </p>
                          )}
                        </>
                      )}
                    </td>

                    <td>
                      <div className="d-flex justify-content-end justify-content-md-center">
                        <div className="dropdown" data-bs-toggle="dropdown">
                          <FaEllipsisVertical
                            className="cursor-pointer px-3 py-1"
                            style={{ fontSize: "2.4rem" }}
                          />
                        </div>
                        <ul className="dropdown-menu mt-2">
                          <li>
                            <Link
                              to={`/extrato/pix/${t.id}`}
                              className="dropdown-item d-flex"
                            >
                              <div
                                className="d-flex justify-content-center align-items-center me-2"
                                style={{ width: 20 }}
                              >
                                <FaCircleInfo />
                              </div>
                              Detalhes da transação
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <TablePagination numeroPaginas={numeroPaginas} />
        </div>
      )}

      {!loading && transacoes.length === 0 && (
        <NoData
          title="Você ainda não possui transações pix"
          description="Não foi encontrado nenhum registro no seu histórico de pix. Assim que houver movimentações, estará disponível seus últimos lançamentos bem aqui."
        />
      )}
    </>
  );
};
