import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { BiTransfer } from "react-icons/bi";
import { InformacoesCobranca } from "./components/informacoes-cobranca";
import MainLayout from "../../../components/Layouts/main";
import { Steps } from "../../../components/Steps";
import ResumoCobranca from "./components/resumo-cobranca";
import { useEmissaoBoletoStore } from "../../../stores/emissao-boleto-store";

export default function EmitirBoleto() {
  const { step } = useEmissaoBoletoStore();

  return (
    <MainLayout>
      <div className="d-flex flex-column mb-4">
        <Link
          to="/boletos"
          className="d-flex align-items-center mb-4 text-decoration-none"
        >
          <FaChevronLeft className="text-black" />
          <span className="text-uppercase fw-bold text-black  mx-3">
            Voltar
          </span>
        </Link>
        <p className="h2 m-0 text-black">Emitir boleto</p>
      </div>

      <Steps
        currentStep={step}
        items={[
          { icon: BiTransfer, text: "Emissão de boleto" },
          // { icon: IoNewspaperOutline, text: "Resumo" },
          // { icon: MdOutlineAttachMoney, text: "Inclusão" },
        ]}
      />

      <div className="bg-white border rounded-bottom d-flex flex-column mb-2">
        {step === 1 && <InformacoesCobranca />}
        {step === 2 && <ResumoCobranca />}
      </div>
    </MainLayout>
  );
}
