import ServiceResult from "../interfaces/service-result";
import api from "./api-client";

export const requestSMSCode = async (senha: string) => {
  let formData = new FormData();
  formData.append("password", senha);

  return api.postForm<ServiceResult>(
    "/auth/solicitar-codigo-verificacao",
    formData,
  );
};
