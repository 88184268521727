import AppRouter from "./routes";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from "react-hot-toast";
import { lazy, Suspense, useEffect } from "react";

const AlmeidaPayTheme = lazy(
  () => import("./components/Styles/AlmeidaPayTheme"),
);
const ContinentalBankTheme = lazy(
  () => import("./components/Styles/ContinentalBankTheme"),
);
const LibertyPayTheme = lazy(
  () => import("./components/Styles/LibertyPayTheme"),
);
const MetropoleCapitalTheme = lazy(
  () => import("./components/Styles/MetropoleCapitalTheme"),
);
const PrestigeNetTheme = lazy(
  () => import("./components/Styles/PrestigeNetTheme"),
);
const ProtectedCapitalTheme = lazy(
  () => import("./components/Styles/ProtectedCapitalTheme"),
);

const tenant = process.env.REACT_APP_TENANT;

export default function App() {
  useEffect(() => {
    const link = document.createElement("link");
    const oldLinks = document.querySelectorAll('link[rel="shortcut icon"]');

    oldLinks.forEach((l) => l.parentNode?.removeChild(l));

    link.id = "dynamic-favicon";
    link.rel = "shortcut icon";
    link.href = process.env.REACT_APP_SHORTLOGO || "/favicon.ico";
    document.title = process.env.REACT_APP_TITLE || "OnFriday BaaS";

    document.head.appendChild(link);
  }, []);

  return (
    <>
      <Suspense fallback={<></>}>
        {tenant === "ALMEIDAPAY" && <AlmeidaPayTheme />}
        {tenant === "CONTINENTALBANK" && <ContinentalBankTheme />}
        {tenant === "LIBERTYPAY" && <LibertyPayTheme />}
        {tenant === "METROPOLECAPITAL" && <MetropoleCapitalTheme />}
        {tenant === "PRESTIGENET" && <PrestigeNetTheme />}
        {tenant === "PROTECTEDCAPITAL" && <ProtectedCapitalTheme />}
      </Suspense>
      <Toaster position="top-right" />
      <AppRouter />
    </>
  );
}
