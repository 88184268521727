export const IconInfo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      data-name="Grupo 21422"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="1"
            d="M0 0H24V24H0z"
            data-name="Retângulo 6730"
          ></path>
        </clipPath>
      </defs>
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip-path)"
        data-name="Grupo 21421"
      >
        <path
          d="M12 8a.25.25 0 10.251.25A.249.249 0 0012 8"
          data-name="Caminho 24531"
        ></path>
        <path
          d="M12 21h0a9 9 0 119-9 9 9 0 01-9 9z"
          data-name="Caminho 24532"
        ></path>
        <path
          d="M0 0L0 5"
          data-name="Linha 288"
          transform="translate(12 12)"
        ></path>
      </g>
    </svg>
  );
}
