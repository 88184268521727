import TransacaoTipo from "../enums/transacao-tipo";

export default function convertEnumTransacaoTipoToString(
  tipo: TransacaoTipo,
): string {
  switch (tipo) {
    // PIX
    case TransacaoTipo.PixRecebimento:
      return "Pix Recebido";

    case TransacaoTipo.PixEnvio:
      return "Pix Enviado";

    case TransacaoTipo.PixRecebimentoTarifa:
    case TransacaoTipo.PixEnvioTarifa:
      return "Tarifa pix";

    case TransacaoTipo.PixRecebimentoDevolucao:
      return "Devolução recebimento";

    case TransacaoTipo.PixEnvioDevolucao:
      return "Devolução de envio";

    case TransacaoTipo.PixDevolucaoTarifa:
    case TransacaoTipo.PixRecebimentoDevolucaoTarifa:
    case TransacaoTipo.PixEnvioDevolucaoTarifa:
      return "Devolução tarifa";

    // TED
    case TransacaoTipo.TedEnvio:
      return "TED Enviado";

    case TransacaoTipo.TedEnvioTarifa:
    case TransacaoTipo.TedRecebimentoTarifa:
      return "Tarifa TED";

    case TransacaoTipo.TedRecebimento:
      return "TED Recebido";

    // Pagamentos
    case TransacaoTipo.PagamentoTitulo:
      return "Pagamento de título";

    case TransacaoTipo.PagamentoContaConsumo:
      return "Pagamento de conta de consumo";

    // Outros
    case TransacaoTipo.ManutencaoContaTarifa:
      return "Tarifa de manutenção de conta";

    default:
      return "Tipo de chave não reconhecida";
  }
}
