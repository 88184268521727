import { create } from "zustand";
import AuthDadosPessoaisModel from "../interfaces/models/auth-dados-pessoais-model";

type StateProps = {
  dadosPessoais?: AuthDadosPessoaisModel | null;
  setDadosPessoais: (args: AuthDadosPessoaisModel) => void;
};

export const useDadosContaStore = create<StateProps>((set) => ({
  dadosPessoais: JSON.parse(sessionStorage.getItem("dadosPessoais") || "{}"),
  setDadosPessoais: (args) => {
    sessionStorage.setItem("dadosPessoais", JSON.stringify(args));
    set({ dadosPessoais: args });
  },
}));
