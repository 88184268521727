import clsx from "clsx";

interface Props {
  title: string;
  description?: string;
}

export const NoData = ({ title, description }: Props) => {
  return (
    <div
      className="d-flex justify-content-center px-3 py-5"
      style={{
        textAlign: "center",
        border: "1px solid #E8E8E8",
        borderRadius: 4,
        backgroundColor: "#E8E8E84D",
      }}
    >
      <div className="col-lg-9 col-xl-6">
        <p className={clsx("h3", description ? "mb-3" : "m-0")}>{title}</p>
        {description && (
          <p
            className="regular-16 m-0"
            style={{ whiteSpace: "pre-line !important" }}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  );
};
