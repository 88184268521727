import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import { IconInfo } from "../Icons/info";
import { NavLinks } from "./components/nav-links";
import { FaArrowsRotate, FaBars } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useSaldoStore } from "../../stores/saldo-store";
import { convertCentsToCurrencyString } from "../../utils/convert-cents-to-currency-string";
import { useUpdateSaldo } from "../../hooks/useUpdateSaldo";

export default function Sidebar() {
  const { saldo, isVisible, setIsVisible } = useSaldoStore();

  const updateSaldo = useUpdateSaldo();

  return (
    <nav className="nav flex-column sidebar" style={{ overflowX: "hidden" }}>
      {/* Sidebar Web */}
      <div className="p-4">
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/">
            <img src={process.env.REACT_APP_LOGO_DARK} alt="logo" style={{ maxWidth: '100%' }} />
          </Link>
          <button
            className="d-block d-md-none bg-transparent border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#staticBackdrop"
            aria-controls="staticBackdrop"
          >
            <FaBars className="text-white" />
          </button>
        </div>

        <hr className="text-white d-none d-md-block" />
        <div className="d-none d-md-flex align-items-center justify-content-between">
          <div className="d-flex flex-wrap align-items-center">
            <p className="text-white regular-14 m-0 me-2">Saldo:</p>
            <p className="text-white text-break semibold-16 m-0">
              {isVisible ? convertCentsToCurrencyString(saldo) : "R$ ••••••"}
            </p>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <button
              type="button"
              onClick={updateSaldo}
              className="d-flex align-items-center border-0 p-2 bg-transparent"
            >
              <FaArrowsRotate className="text-white" />
            </button>

            <button
              type="button"
              className="d-flex align-items-center border-0 p-2 bg-transparent"
              onClick={() => setIsVisible(!isVisible)}
            >
              {isVisible ? (
                <FaRegEyeSlash className="text-white" />
              ) : (
                <FaEye className="text-white" />
              )}
            </button>
          </div>
        </div>
        <hr className="text-white d-none d-md-block" />

        <div className="d-none d-md-flex flex-column justify-content-between">
          <NavLinks />
          <div className="d-flex flex-column">
            <div className="mt-5 p-0">
              <hr className="text-white w-100" />
            </div>
            <Link
              to="/"
              className="text-decoration-none text-white w-100 d-flex align-items-center mb-3"
            >
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ width: "40px" }}
              >
                <IconInfo />
              </div>
              <span className="text-white medium-14">Precisa de ajuda?</span>
            </Link>
          </div>
        </div>

        {/* Sidebar Mobile */}
        <div
          className="offcanvas offcanvas-start bg-sidebar"
          data-bs-backdrop="static"
          tabIndex={-1}
          id="staticBackdrop"
          aria-labelledby="staticBackdropLabel"
        >
          <div className="offcanvas-header mt-3 ms-2">
            <img src={process.env.REACT_APP_LOGO} alt="logo" width={159} />
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <hr className="text-white" />
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex flex-wrap align-items-center">
                <p className="text-white regular-14 m-0 me-2">Saldo:</p>
                <p className="text-white text-break semibold-16 m-0">
                  {isVisible
                    ? convertCentsToCurrencyString(saldo)
                    : "R$ ••••••"}
                </p>
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  onClick={updateSaldo}
                  className="d-flex align-items-center border-0 p-2 bg-transparent"
                >
                  <FaArrowsRotate className="text-white" />
                </button>

                <button
                  type="button"
                  className="d-flex align-items-center border-0 p-2 bg-transparent"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  {isVisible ? (
                    <FaRegEyeSlash className="text-white" />
                  ) : (
                    <FaEye className="text-white" />
                  )}
                </button>
              </div>
            </div>
            <hr className="text-white" />
            <div className="d-flex flex-column justify-content-between">
              <NavLinks />
              <div className="d-flex flex-column">
                <div className="mt-5 p-0">
                  <hr className="text-white w-100" />
                </div>
                <Link
                  to="/"
                  className="text-decoration-none text-white w-100 d-flex align-items-center mb-3"
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ width: "40px" }}
                  >
                    <IconInfo />
                  </div>
                  <span className="text-white medium-14">
                    Precisa de ajuda?
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
