export const IconPagarConta = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" data-name="Grupo 10876">
        <path
          d="M1507.619 3079.719h24v24h-24z"
          data-name="Caminho 3208"
          transform="translate(-1507.619 -3079.719)"
        ></path>
        <g
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          data-name="Grupo 10875"
          transform="translate(3 3)"
        >
          <path d="M8.75 4v1.25" data-name="Caminho 3209"></path>
          <path d="M8.75 14v-1.25" data-name="Caminho 3210"></path>
          <path
            d="M11.237 7a1.869 1.869 0 00-1.862-1.75h-1.37a1.755 1.755 0 00-.427 3.458l2.343.588a1.755 1.755 0 01-.427 3.458h-1.37A1.87 1.87 0 016.261 11"
            data-name="Caminho 3211"
          ></path>
          <path d="M7.5 18h3" data-name="Caminho 3212"></path>
          <path d="M10.5 0h-3" data-name="Caminho 3213"></path>
          <path d="M0 7.5v3" data-name="Caminho 3214"></path>
          <path d="M18 10.5v-3" data-name="Caminho 3215"></path>
          <path
            d="M18 3V1.214A1.215 1.215 0 0016.786 0H15"
            data-name="Caminho 3216"
          ></path>
          <path
            d="M0 15v1.786A1.215 1.215 0 001.214 18H3"
            data-name="Caminho 3217"
          ></path>
          <path
            d="M3 0H1.214A1.215 1.215 0 000 1.214V3"
            data-name="Caminho 3218"
          ></path>
          <path
            d="M15 18h1.786A1.215 1.215 0 0018 16.786V15"
            data-name="Caminho 3219"
          ></path>
        </g>
      </g>
    </svg>
  );
}
