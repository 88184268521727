import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import MainLayout from "../../components/Layouts/main";
import { useDadosContaStore } from "../../stores/dados-conta-store";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function Perfil() {
  const navigate = useNavigate();
  const { dadosPessoais } = useDadosContaStore();

  if (!dadosPessoais) {
    navigate(-1);
    toast.error("Não foi possível obter seus dados!");
  }

  const {
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nomeCompleto: dadosPessoais?.nomeCompleto,
      agencia: dadosPessoais?.conta?.agencia,
      conta: dadosPessoais?.conta?.numero,
      digito: dadosPessoais?.conta?.digito,
    },
  });

  return (
    <MainLayout>
      <div className="row pt-4 pb-5 d-flex align-items-center">
        <div className="col-sm-6">
          <p className="h1 p-0 m-0">Minha conta</p>
        </div>
      </div>

      <div className="row">
        <div className="accordion" id="accordionExample">
          {/* dados pessoais */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button shadow-none bg-primary text-black"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseDadosPessoais"
                aria-expanded="true"
                aria-controls="collapseDadosPessoais"
              >
                <p className="h3 m-0">Dados Pessoais</p>
              </button>
            </h2>

            <div
              id="collapseDadosPessoais"
              className="accordion-collapse collapse show pt-3"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body pt-0 pb-4 px-4">
                <div className="col-md-6">
                  <form>
                    <div className="row mb-3">
                      <Input
                        control={control}
                        errors={errors}
                        validation={{}}
                        placeholder="Nome Completo"
                        name="nomeCompleto"
                        type="text"
                        label="Nome Completo"
                        disabled
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* fim dados pessoais */}

          {/* dados da conta */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed shadow-none bg-primary text-black"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseDadosConta"
                aria-expanded="false"
                aria-controls="collapseDadosConta"
              >
                <p className="h3 m-0">Dados da conta</p>
              </button>
            </h2>

            <div
              id="collapseDadosConta"
              className="accordion-collapse collapse pt-3"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body pt-0 pb-4 px-4">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        control={control}
                        errors={errors}
                        validation={{}}
                        placeholder="Agência"
                        name="agencia"
                        type="text"
                        label="Agência"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row py-2">
                    <div className="col-md-6">
                      <Input
                        control={control}
                        errors={errors}
                        validation={{}}
                        placeholder="Número da conta"
                        name="conta"
                        type="text"
                        label="Número da conta"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row py-2">
                    <div className="col-md-6">
                      <Input
                        control={control}
                        errors={errors}
                        validation={{}}
                        placeholder="Dígito"
                        name="digito"
                        type="text"
                        label="Dígito"
                        disabled
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* fim dados da conta */}
        </div>
      </div>
    </MainLayout>
  );
}
