import ReactModal from "react-modal";
import { IoClose } from "react-icons/io5";
import clsx from "clsx";

ReactModal.setAppElement("#root");

export interface ModalProps {
  children?: string | JSX.Element | JSX.Element[];
  isOpen: boolean;
  close: () => void | ((args: any) => void);
  width?: string;
  title?: string;
  overlayClose?: boolean;
}

export default function EmptyModal({
  children,
  isOpen,
  close,
  width,
  title,
  overlayClose = false,
}: ModalProps) {
  const customStyles = {
    content: {
      maxWidth: width,
    },
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnEsc={overlayClose}
      shouldCloseOnOverlayClick={overlayClose}
      style={customStyles}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div
        className={clsx(
          "mb-4 d-flex align-items-center",
          title ? "justify-content-between" : "justify-content-end",
        )}
      >
        {title && <p className="h2 text-black m-0">{title}</p>}
        <IoClose onClick={close} className="text-danger fs-4 cursor-pointer" />
      </div>
      {children}
    </ReactModal>
  );
}
