import { create } from "zustand";

type SaldoStore = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  saldo: number;
  setSaldo: (saldo: number) => void;
};

export const useSaldoStore = create<SaldoStore>()((set) => ({
  isVisible: false,
  setIsVisible: (isVisible) => set({ isVisible }),
  saldo: 0,
  setSaldo: (saldo) => set({ saldo }),
}));
