import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { FaChevronLeft } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import MainLayout from "../../../components/Layouts/main";
import InputCurrency from "../../../components/InputCurrency";
import { useCobrarPIXStore } from "../../../stores/cobrar-pix-store";
import api from "../../../services/api-client";
import PixRecebimentosPostArgs from "../../../interfaces/args/pix-recebimentos-post.args";
import somenteNumeros from "../../../utils/somente-numeros";
import ServiceResult from "../../../interfaces/service-result";
import RecebimentoPixModel from "../../../interfaces/models/recebimento-pix.model";
import apiErrorHandler from "../../../services/api-error-handler";
import { useState } from "react";

interface FormValues {
  valor: string;
  descricao: string;
}

export default function CobrarPIX() {
  const navigate = useNavigate();
  const { setQrCode } = useCobrarPIXStore();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    values: {
      valor: "",
      descricao: "",
    },
  });

  async function onSubmit(data: FormValues): Promise<void> {
    setLoading(true);

    let args: PixRecebimentosPostArgs = {
      callbackAddress: null,
      codigoExterno: null,
      valorCentavos: Number(somenteNumeros(data.valor || "0")),
      gerarBase64: false,
    };

    api
      .post<ServiceResult<RecebimentoPixModel>>("/pix/recebimentos", args)
      .then(({ data: result }) => {
        toast.success("Codigo QR gerado com sucesso!");
        setQrCode(result.data?.qrCode.codigo as string);
        navigate("/pix/cobrar/qrcode");
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  return (
    <MainLayout>
      <div className="d-flex flex-column mb-4">
        <Link
          to="/pix"
          className="d-flex align-items-center mb-4 text-decoration-none"
        >
          <FaChevronLeft className="text-black" />
          <span className="text-uppercase fw-bold text-black  mx-3">
            Voltar
          </span>
        </Link>
        <p className="h2 m-0">Receber PIX</p>
      </div>

      <div className="bg-white border rounded-bottom d-flex flex-column mb-2">
        <p className="h2 mx-3 mx-md-5 my-4">Informe o valor que irá receber</p>

        {/* FORM */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row px-3 px-md-5">
            {/* <div className="col-lg-8"> */}
            <div className="col-lg-12">
              <div className="row">
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <InputCurrency
                      control={control}
                      errors={errors}
                      name="valor"
                      label="Valor"
                      validation={{
                        required: "Campo obrigatório",
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-12 mb-3">
                  <label htmlFor="data" className="form-label">
                    Descrição (opcional)
                  </label>

                  <input
                    {...register("descricao")}
                    type="text"
                    className="form-control"
                    placeholder="Insira uma descrição para a cobrança"
                  />
                </div>
              </div>
            </div>

            {/*
            <div className="col-lg-4">
              <div
                className="border rounded bg-light p-3 p-md-4"
                style={{ color: "#777777" }}
              >
                <p className="semibold-14 mb-3">COBRAR PIX</p>
                <p className="regular-14 mb-3">
                  Você tem a opção de gerar um QR code sem valor vinculado,
                  nesse caso, a pessoa que vai efetuar o pagamento preenche o
                  valor.
                </p>
              </div>
            </div>
            */}
          </div>

          {/* FORM BUTTONS */}
          <div className="p-3 p-md-4 d-flex flex-md-row justify-content-between justify-content-md-end align-items-center">
            <Link
              to="/pix"
              className="d-flex align-items-center text-decoration-none cursor-pointer m-0 me-md-4"
            >
              <FaChevronLeft className="text-black" />
              <span className="text-uppercase fw-bold text-black ms-2">
                Voltar
              </span>
            </Link>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary"
            >
              {loading ? "Gerando QR Code..." : "Gerar QR Code"}
            </button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
}
