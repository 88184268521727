import clsx from "clsx";
import { useForm } from "react-hook-form";
import { usePagarContaStore } from "../../../stores/pagar-conta-store";
import api from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import apiErrorHandler from "../../../services/api-error-handler";
import { useState } from "react";
import ConsultaBoletoModel from "../../../interfaces/models/consulta-boleto.model";
import somenteNumeros from "../../../utils/somente-numeros";
import { toast } from "react-hot-toast";

interface FormValues {
  codigoDeBarrasOuLinhaDigitavel: string;
}

export const InserirCodigo = () => {
  const { setStep, setConsulta, setCodigoBarras, codigoBarras } =
    usePagarContaStore();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      codigoDeBarrasOuLinhaDigitavel: codigoBarras || "",
    },
  });

  async function onSubmit(data: FormValues): Promise<void> {
    const codigoDeBarras = somenteNumeros(data.codigoDeBarrasOuLinhaDigitavel);

    if (codigoDeBarras?.length === 0) {
      toast.error("Código de barras inválido");
      return;
    }

    setLoading(true);

    api
      .get<ServiceResult<ConsultaBoletoModel>>(
        `/pagamentos/${somenteNumeros(
          data.codigoDeBarrasOuLinhaDigitavel,
        )}/consultar`,
      )
      .then(({ data: result }) => {
        setConsulta(result.data as ConsultaBoletoModel);
        setCodigoBarras(data.codigoDeBarrasOuLinhaDigitavel);
        setStep(2);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  return (
    <>
      <p className="h2 mx-3 mx-md-5 my-4">Insira o código de barras</p>

      {/* FORM */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row px-3 px-md-5">
          {/* <div className="col-lg-6 mb-3">
            <label htmlFor="recipient-name" className="col-form-label">
              Selecione o tipo de boleto:
            </label>
            <select
              {...register("tipo", {
                required: "Tipo de boleto é obrigatório",
              })}
              className="form-select"
            >
              {Object.values(BoletoTipo).map((tipo) => (
                <option key={tipo} value={tipo}>
                  {convertEnumBoletoTipoToString(tipo)}
                </option>
              ))}
            </select>
          </div> */}

          <div className="col-lg-12 mb-3">
            <label htmlFor="chave" className="form-label">
              Código de barras
            </label>

            <input
              {...register("codigoDeBarrasOuLinhaDigitavel", {
                required: "Código de barras é obrigatório",
              })}
              className={clsx(
                "form-control",
                errors.codigoDeBarrasOuLinhaDigitavel &&
                  "border-danger is-invalid",
              )}
              placeholder="Insira o código de barras"
            />

            {errors.codigoDeBarrasOuLinhaDigitavel && (
              <p className="text-danger m-0">
                {errors.codigoDeBarrasOuLinhaDigitavel.message?.toString()}
              </p>
            )}
          </div>

          {/* {watch("tipo") === BoletoTipo.TributoDarfCodigoDeBarras && (
            <div className="col-lg-12 mb-3">
              <label htmlFor="chave" className="form-label">
                Documento
              </label>

              <input
                {...register("documento", {
                  required: "Documento é obrigatório para DARF",
                })}
                className={clsx(
                  "form-control",
                  errors.documento && "border-danger is-invalid"
                )}
                placeholder="CPF/CNPJ"
              />

              {errors.documento && (
                <p className="text-danger m-0">
                  {errors.documento.message?.toString()}
                </p>
              )}
            </div>
          )} */}
        </div>

        {/* FORM BUTTONS */}
        <div className="p-3 p-md-4 d-flex justify-content-end align-items-center">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? "Carregando..." : "Avançar"}
          </button>
        </div>
      </form>
    </>
  );
};
