enum ChavePixTipo {
  CPF = "CPF",
  CNPJ = "CNPJ",
  Email = "Email",
  Telefone = "Telefone",
  EVP = "EVP",
  // Manual = "Manual",
}

export default ChavePixTipo;
