import moment from "moment";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { FaFileUpload } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import PessoaNatureza from "../../../../enums/pessoa-natureza";
import PropostaArgs from "../../../../interfaces/args/proposta.args";
import ServiceResult from "../../../../interfaces/service-result";
import api from "../../../../services/api-client";
import apiErrorHandler from "../../../../services/api-error-handler";
import { useCadastroPFStore } from "../../../../stores/cadastro-pf-store";
import somenteNumeros from "../../../../utils/somente-numeros";

export const Documentos = () => {
  const navigate = useNavigate();

  const { dadosPessoais, setStep, fotoDocumentoFile, selfieDocumentoFile } =
    useCadastroPFStore();

  const [loading, setLoading] = useState<boolean>(false);

  const fotoDocumentoRef = useRef<HTMLInputElement | null>(null);
  const selfieDocumentoRef = useRef<HTMLInputElement | null>(null);

  const { handleSubmit } = useForm();

  function handleFileUpload(
    event: React.ChangeEvent<HTMLInputElement>,
    type: "foto" | "selfie",
  ) {
    const file = event.target.files?.[0];

    if (!file) return;

    switch (type) {
      case "foto":
        useCadastroPFStore.setState({
          fotoDocumentoFile: file,
        });
        break;

      case "selfie":
        useCadastroPFStore.setState({
          selfieDocumentoFile: file,
        });
        break;

      default:
        break;
    }
  }

  function validate(): boolean {
    if (!fotoDocumentoFile && !selfieDocumentoFile) {
      toast.error(
        <p className="m-0">
          Envie os arquivos: <strong>Foto com documento</strong> e{" "}
          <strong>Selfie com documento</strong> para continuar.
        </p>,
      );
      return false;
    }

    if (!fotoDocumentoFile) {
      toast.error(
        <p className="m-0">
          Envie o arquivo: <strong>Foto com documento</strong> para continuar.
        </p>,
      );
      return false;
    }

    if (!selfieDocumentoFile) {
      toast.error(
        <p className="m-0">
          Envie o arquivo: <strong>Selfie com documento</strong> para continuar.
        </p>,
      );
      return false;
    }

    return true;
  }

  async function onSubmit(): Promise<void> {
    if (!validate()) {
      return;
    }

    setLoading(true);

    let args: PropostaArgs = {
      dadosPessoais: {
        cpf: somenteNumeros(dadosPessoais.cpf),
        dataNascimento: moment(
          dadosPessoais.dataNascimento,
          "DD/MM/YYYY",
        ).format("YYYY-MM-DD"),
        email: dadosPessoais.email,
        endereco: {
          bairro: dadosPessoais.endereco?.bairro ?? "",
          cep: somenteNumeros(dadosPessoais.endereco?.cep ?? ""),
          cidade: dadosPessoais.endereco?.cidade ?? "",
          complemento: dadosPessoais.endereco?.complemento ?? null,
          estado: dadosPessoais.endereco?.estado ?? "",
          lougradouro: dadosPessoais.endereco?.lougradouro ?? "",
          numero: dadosPessoais.endereco?.numero ?? "",
        },
        estadoCivil: dadosPessoais.estadoCivil,
        nacionalidade: dadosPessoais.nacionalidade,
        nomeCompleto: dadosPessoais.nomeCompleto,
        rg: somenteNumeros(dadosPessoais.rg),
        rgUf: dadosPessoais.rgUf,
        telefoneContato: somenteNumeros(dadosPessoais.telefoneContato),
      },
      dadosEmpresa: null,
      natureza: PessoaNatureza.Fisica,
      socios: [],
    };

    let formData = new FormData();
    formData.append("jsonData", JSON.stringify(args));
    formData.append(
      "PessoaFisica.ArquivoFotoDocumento",
      fotoDocumentoFile!,
      fotoDocumentoFile!.name,
    );
    formData.append(
      "PessoaFisica.ArquivoFotoSelfieComDocumento",
      selfieDocumentoFile!,
      selfieDocumentoFile!.name,
    );

    api
      .postForm<ServiceResult<string>>("/propostas", formData)
      .then(() => {
        useCadastroPFStore.setState({
          fotoDocumentoFile: null,
          selfieDocumentoFile: null,
        });

        navigate("/login");

        toast.success(
          <p className="m-0">
            Sua proposta de cadastro foi enviada com sucesso!
            <br />
            Assim que aprovado você receberá um e-mail para definir uma senha de
            acesso a sua conta.
          </p>,
          { duration: 10000 },
        );
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-lg-5 mb-5 pb-4">
        <div className="row m-3">
          <p className="h1 mb-4">Adicione a documentação</p>
          <p className="h2 mb-3">Escolher arquivos</p>
        </div>

        <div className="row m-3">
          <div className="col-lg-12 mb-3">
            <label className="form-label">Foto com documento</label>

            {/* Input file hidden */}
            <input
              ref={fotoDocumentoRef}
              style={{ display: "none" }}
              type="file"
              accept="*"
              multiple={false}
              onChange={(e) => handleFileUpload(e, "foto")}
            />

            <button
              type="button"
              className="btn bg-white w-100 d-flex align-items-center justify-content-center"
              style={{
                border: "2px solid #d2d6dc",
                height: 54,
              }}
              onClick={() => {
                if (loading) return;
                fotoDocumentoRef.current?.click();
              }}
            >
              <FaFileUpload className="me-2" style={{ color: "#777777" }} />
              <p className="medium-16 m-0" style={{ color: "#777777" }}>
                Escolher arquivo
              </p>
            </button>

            {fotoDocumentoFile && (
              <div className="max-w-100 text-break mt-2 d-inline-flex justify-content-between align-items-center border border-dark rounded p-2">
                <p className="m-0">{fotoDocumentoFile.name}</p>
                <IoClose
                  size={20}
                  className="ms-2 cursor-pointer text-danger"
                  onClick={() => {
                    useCadastroPFStore.setState({
                      fotoDocumentoFile: null,
                    });
                  }}
                />
              </div>
            )}
          </div>

          <div className="col-lg-12 mb-3">
            <label className="form-label">Selfie com documento</label>

            {/* Input file hidden */}
            <input
              ref={selfieDocumentoRef}
              style={{ display: "none" }}
              type="file"
              accept="*"
              multiple={false}
              onChange={(e) => handleFileUpload(e, "selfie")}
            />

            <button
              type="button"
              className="btn bg-white w-100 d-flex align-items-center justify-content-center"
              style={{
                border: "2px solid #d2d6dc",
                height: 54,
              }}
              onClick={() => {
                if (loading) return;
                selfieDocumentoRef.current?.click();
              }}
            >
              <FaFileUpload className="me-2" style={{ color: "#777777" }} />
              <p className="medium-16 m-0" style={{ color: "#777777" }}>
                Escolher arquivo
              </p>
            </button>

            {selfieDocumentoFile && (
              <div className="max-w-100 text-break mt-2 d-inline-flex justify-content-between align-items-center border border-dark rounded p-2">
                <p className="m-0">{selfieDocumentoFile.name}</p>
                <IoClose
                  size={20}
                  className="ms-2 cursor-pointer text-danger"
                  onClick={() => {
                    useCadastroPFStore.setState({
                      selfieDocumentoFile: null,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* WEB */}
      <div
        className={
          "position-fixed bottom-0 bg-white w-50 d-none d-lg-flex justify-content-between align-items-center p-3"
        }
        style={{ borderTop: "1px solid #E5E5E5" }}
      >
        <button
          type="button"
          className="btn d-flex align-items-center"
          onClick={() => setStep("dados-pessoais")}
        >
          <FaChevronLeft className="me-2" />
          <p className="semibold-14 m-0 text-uppercase">Voltar</p>
        </button>

        <button type="submit" disabled={loading} className="btn btn-primary">
          {loading ? "Enviando..." : "Criar conta"}
        </button>
      </div>

      {/* MOBILE */}
      <div
        className={
          "position-fixed bottom-0 bg-white w-100 d-flex d-lg-none justify-content-between align-items-center p-3"
        }
        style={{ borderTop: "1px solid #E5E5E5" }}
      >
        <button
          type="button"
          className="btn d-flex align-items-center"
          onClick={() => setStep("dados-pessoais")}
        >
          <FaChevronLeft className="me-2" />
          <p className="semibold-14 m-0 text-uppercase">Voltar</p>
        </button>

        <button type="submit" disabled={loading} className="btn btn-primary">
          {loading ? "Enviando..." : "Criar conta"}
        </button>
      </div>
    </form>
  );
};
