import axios from "axios";
import { toast } from "react-hot-toast";
import AuthModel from "../interfaces/models/auth.model";

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

const api = axios.create(defaultOptions);

api.interceptors.request.use((config) => {
  const auth: AuthModel = JSON.parse(sessionStorage.getItem("auth") ?? "{}");

  config.headers.Authorization = `Bearer ${auth.accessToken}`;

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // PRECISA TESTAR
    // if (
    //   error.response &&
    //   error.response.status === 401 &&
    //   window.location.pathname !== "/login"
    // ) {
    //   toast.error("Sua sessão expirou. Faça login novamente.");
    //   sessionStorage.removeItem("auth");
    //   setTimeout(() => {
    //     window.location.href = "/login";
    //   }, 2e3);
    // }

    return Promise.reject(error);
  },
);

export default api;
