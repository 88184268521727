import { FaFileInvoiceDollar, FaPix } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useEnvioPIXStore } from "../../../stores/enviar-pix-store";

export const Cards = () => {
  const { setStep } = useEnvioPIXStore();

  return (
    <div className="row mb-4 mx-0">
      <div className="col-md-4 p-0 d-flex flex-column">
        <Link to="/pix/enviar" onClick={() => setStep(1)} className="card-link">
          <FaPix className="mb-2" style={{ fontSize: "1.7rem" }} />
          <p className="h4 m-0">Enviar PIX</p>
        </Link>
      </div>

      <div className="col-md-4 p-0 py-3 py-md-0 px-md-3  d-flex flex-column">
        <Link to="/pix/cobrar" className="card-link">
          <FaPix className="mb-2" style={{ fontSize: "1.7rem" }} />
          <p className="h4 m-0">Receber PIX</p>
        </Link>
      </div>

      <div className="col-md-4 p-0 d-flex flex-column">
        <Link to="/pix/extrato" className="card-link">
          <FaFileInvoiceDollar
            className="mb-2"
            style={{ fontSize: "1.7rem" }}
          />
          <p className="h4 m-0">Extrato PIX</p>
        </Link>
      </div>
    </div>
  );
};
