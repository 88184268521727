import MainLayout from "../components/Layouts/main";

export default function Dev() {
  return (
    <MainLayout>
      <p className="h1 m-0">🚧 Página em desenvolvimento</p>

      <p className="h3 regular-16 mt-4">
        Em breve você poderá acessar essa funcionalidade, pedimos desculpas pelo
        transtorno.
      </p>

      <img
        src="/dev.svg"
        alt="Desenvolvimento"
        className="img-fluid"
        style={{ maxHeight: 700 }}
      />
    </MainLayout>
  );
}
