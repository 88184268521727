import clsx from "clsx";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FaChevronLeft } from "react-icons/fa6";
import { Info } from "../../../components/TextCards/info";
import { usePagarContaStore } from "../../../stores/pagar-conta-store";
import { useState } from "react";
import { requestSMSCode } from "../../../services/confirm-sms.service";
import { useNavigate } from "react-router-dom";
import apiErrorHandler from "../../../services/api-error-handler";

interface FormValues {
  senha: string;
}

export const Inclusao = () => {
  const { setStep } = usePagarContaStore();

  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>();

  async function onSubmit(data: FormValues): Promise<void> {
    setLoading(true);

    requestSMSCode(data.senha)
      .then(() => navigate("/pagar-conta/confirmacao"))
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  return (
    <>
      <p className="h2 mx-3 mx-md-5 my-4">Autorização da inclusão</p>

      <div className="row px-3 px-md-5 mb-4">
        <div className="col-lg-12">
          <Info text="Insira sua senha para autorizar a operação." />
        </div>
      </div>

      {/* FORM */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row px-3 px-md-5">
          <div className="col-lg-12 mb-3">
            <label htmlFor="senha" className="form-label">
              Senha
            </label>

            <input
              type="password"
              {...register("senha", {
                required: "Campo obrigatório",
              })}
              className={clsx(
                "form-control",
                errors.senha && "is-invalid border-danger",
              )}
              placeholder="Insira sua senha"
            />

            {errors.senha && (
              <p className="text-danger m-0">
                {errors.senha.message?.toString()}
              </p>
            )}
          </div>
        </div>

        {/* FORM BUTTONS */}
        <div className="p-3 p-md-4 d-flex flex-md-row justify-content-between justify-content-md-end align-items-center">
          <p
            onClick={() => setStep(2)}
            className="d-flex align-items-center text-decoration-none cursor-pointer m-0 me-md-4"
          >
            <FaChevronLeft className="text-black" />
            <span className="text-uppercase fw-bold text-black ms-2">
              Voltar
            </span>
          </p>
          <button type="submit" disabled={loading} className="btn btn-primary">
            {loading ? "Carregando..." : "Avançar"}
          </button>
        </div>
      </form>
    </>
  );
};
