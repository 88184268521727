import { create } from "zustand";
import ConsultaBoletoModel from "../interfaces/models/consulta-boleto.model";

type PagarContaStore = {
  step: 1 | 2 | 3;
  setStep: (step: 1 | 2 | 3) => void;

  consulta: ConsultaBoletoModel;
  setConsulta: (args: ConsultaBoletoModel) => void;

  codigoBarras: string;
  setCodigoBarras: (args: string) => void;
};

export const usePagarContaStore = create<PagarContaStore>()((set) => ({
  step: 1,
  setStep: (step) => set({ step }),

  consulta: {} as ConsultaBoletoModel,
  setConsulta: (consulta) => {
    set((state) => {
      return {
        ...state,
        consulta,
      };
    });
  },

  codigoBarras: "",
  setCodigoBarras: (codigoBarras) => {
    set((state) => {
      return {
        ...state,
        codigoBarras,
      };
    });
  },
}));
