import { useNavigate } from "react-router-dom";
import CleanLayout from "../../components/Layouts/clean";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import { useState } from "react";
import somenteNumeros from "../../utils/somente-numeros";
import api from "../../services/api-client";
import toast from "react-hot-toast";
import apiErrorHandler from "../../services/api-error-handler";

interface FormValues {
  cpf: string;
}

export default function RecuperarSenhaToken() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>();

  async function handleEsqueciSenha(data: FormValues): Promise<void> {
    setLoading(true);

    let args = {
      cpf: somenteNumeros(data.cpf),
    };

    api
      .post("/auth/esqueci-minha-senha", args)
      .then(() => {
        navigate("/login");

        toast.success(
          <p className="m-0">
            Recuperar Senha!
            <br />
            Você receberá um e-mail para definir uma senha de acesso a sua
            conta.
          </p>,
          { duration: 10000 }
        );
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => setLoading(false));
  }

  return (
    <CleanLayout backAction={() => navigate("/login")}>
      <div className="w-100 row d-flex justify-content-center">
        <div className="col-md-6 col-lg-4">
          <form
            className="w-100 d-flex flex-column"
            onSubmit={handleSubmit(handleEsqueciSenha)}
          >
            <Input
              control={control}
              errors={errors}
              mask="999.999.999-99"
              validation={{
                required: "Campo obrigatório",
                minLength: {
                  value: 14,
                  message: "O CPF deve conter 11 dígitos",
                },
                maxLength: {
                  value: 14,
                  message: "O CPF deve conter 11 dígitos",
                },
              }}
              label="Informe seu CPF"
              name="cpf"
            />

            <button
              className="w-100 btn btn-primary mt-4"
              disabled={loading}
              type="submit"
            >
              {loading ? "Aguarde..." : "Enviar"}
            </button>
          </form>
        </div>
      </div>
    </CleanLayout>
  );
}
