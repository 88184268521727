import { useEffect } from "react";
import { Link } from "react-router-dom";
import AuthLayout from "../../../components/Layouts/auth";
import { useCadastroPFStore } from "../../../stores/cadastro-pf-store";
import { DadosPessoais } from "./components/dados-pessoais";
import { Documentos } from "./components/documentos";
import { Header } from "./components/header";

export default function CadastroPF() {
  const { step } = useCadastroPFStore();

  async function verificarCadastroEmAberto(): Promise<void> {
    // verificar se existe um cadastro em aberto
    // se existir, carregar os dados do cadastro (setar os valores no store)
  }

  useEffect(() => {
    verificarCadastroEmAberto();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout page="cadastro">
      <Header />

      <div className="row m-3 my-4">
        <p className="regular d-flex justify-content-end p-0 m-0">
          Já possui conta?
          <Link
            to="/login"
            className="p-0 m-0 ms-1 semibold-16 text-black text-decoration-none"
          >
            Faça o login
          </Link>
        </p>
      </div>

      {step === "dados-pessoais" && <DadosPessoais />}
      {step === "documentos" && <Documentos />}
    </AuthLayout>
  );
}
