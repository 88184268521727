import { Link } from "react-router-dom";
import MainLayout from "../../components/Layouts/main";
import { TableBoleto } from "./components/table-boleto";

export default function Boleto() {
  return (
    <MainLayout>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <p className="h1 m-0">Boletos</p>
        <Link
          to="/boletos/emitir"
          className="btn-primary border-0 py-2 px-3 rounded-pill text-decoration-none"
        >
          <span className="text-uppercase fw-bold">Emitir novo</span>
        </Link>
      </div>

      <TableBoleto />
    </MainLayout>
  );
}
