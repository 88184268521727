export const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      data-name="Grupo 16349"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="none"
            stroke="#fff"
            strokeWidth="1"
            d="M0 0H24V24H0z"
            data-name="Retângulo 4515"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)" data-name="Grupo 16348">
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M9.5 20.5V16a2.5 2.5 0 015 0v4.5H20v-8.586a2 2 0 00-.586-1.414l-6.707-6.707a1 1 0 00-1.414 0L4.586 10.5A2 2 0 004 11.914V20.5z"
          data-name="Caminho 22755"
        ></path>
      </g>
    </svg>
  );
}