import moment from "moment";
import { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { MdOutlineHelpOutline } from "react-icons/md";
import { Info } from "../../../../components/TextCards/info";
import BoletoMoraTipo from "../../../../enums/boleto-mora-tipo";
import BoletoMultaTipo from "../../../../enums/boleto-multa-tipo";
import PessoaNatureza from "../../../../enums/pessoa-natureza";
import BoletosRecebimentosPostArgs from "../../../../interfaces/args/boletos-recebimentos-post.args";
import api from "../../../../services/api-client";
import apiErrorHandler from "../../../../services/api-error-handler";
import { useEmissaoBoletoStore } from "../../../../stores/emissao-boleto-store";
import somenteNumeros from "../../../../utils/somente-numeros";

export default function ResumoCobranca() {
  const { setStep } = useEmissaoBoletoStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { args } = useEmissaoBoletoStore();

  async function emitirBoleto(): Promise<void> {
    // let body: BoletosRecebimentosPostArgs = {
    //   valorCentavos: Number(somenteNumeros(args.valorCentavos.toString())),
    //   dataDocumento: null,
    //   dataVencimento: moment(args.dataVencimento, "DD/MM/YYYY").format(
    //     "YYYY-MM-DD",
    //   ),
    //   descontoTipo: null,
    //   descontoData: null,
    //   descontoTaxa: null,
    //   descontoValorCentavos: null,
    //   pagadorEndereco: {
    //     cep: somenteNumeros(args.pagadorEndereco.cep),
    //     bairro: args.pagadorEndereco.bairro,
    //     cidade: args.pagadorEndereco.cidade,
    //     complemento: args.pagadorEndereco.complemento,
    //     logradouro: args.pagadorEndereco.logradouro,
    //     numero: args.pagadorEndereco.numero,
    //     pais: args.pagadorEndereco.pais,
    //     unidadeFederacao: args.pagadorEndereco.unidadeFederacao,
    //   },
    //   pagadorNome: args.pagadorNome,
    //   pagadorDocumento: somenteNumeros(args.pagadorDocumento),
    //   pagadorNatureza:
    //     somenteNumeros(args.pagadorDocumento).length === 11
    //       ? PessoaNatureza.Fisica
    //       : PessoaNatureza.Juridica,
    //   pagadorEmail: args.pagadorEmail,
    //   pagadorTelefone: args.pagadorTelefone
    //     ? somenteNumeros(args.pagadorTelefone)
    //     : null,
    //   moraTipo: BoletoMoraTipo.NaoAplicavel,
    //   moraTaxa: null,
    //   moraValorCentavos: null,
    //   multaTipo: BoletoMultaTipo.NaoAplicavel,
    //   multaTaxa: null,
    //   multaValorCentavos: null,
    //   numeroIdentificacao: args.numeroIdentificacao,
    //   descricao: args.descricao || null,
    //   callbackAddress: null,
    // };
    //
    // if (args.multaTaxa && args.multaTaxa > 0) {
    //   body.multaTipo = BoletoMultaTipo.Percentual;
    //   body.multaTaxa = Number(args.multaTaxa.toString().replace(",", "."));
    // }
    //
    // setLoading(true);
    //
    // api
    //   .post("/boletos", body)
    //   .then(({ data: result }) => {
    //     console.log(result);
    //   })
    //   .catch((err) => apiErrorHandler(err))
    //   .finally(() => setLoading(false));
  }

  return (
    <div className="px-3 px-md-5 mt-4">
      <div className="d-flex flex-column align-items-center justify-content-center mt-4 mb-4">
        <div className="d-flex align-items-center">
          <p className="h2">Resumo da cobrança</p>
          <MdOutlineHelpOutline className="h2 mx-2" />
        </div>
        <div className="mt-2">
          <Info
            text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
          />
        </div>
        <div className="d-flex justify-content-center border rounded bg-light mt-3 p-4">
          <div className="row">
            <div className="col-sm-4 d-flex flex-column justify-content-center">
              <div className="row mb-4">
                <p className="mb-2 semibold-16">Favorecido</p>
                <p className="m-0 regular-16">Focus Lab Design</p>
              </div>
              <div className="row mb-4">
                <p className="mb-2 semibold-16">Valor</p>
                <p className="m-0 regular-16">R$250,00</p>
              </div>
            </div>

            <div className="col-sm-4 d-flex flex-column justify-content-center">
              <div className="row mb-4">
                <p className="mb-2 semibold-16">CPF/CNPJ</p>
                <p className="m-0 regular-16">770.024.665/0001-55</p>
              </div>
              <div className="row mb-4">
                <p className="mb-2 semibold-16">Nº de Identificação</p>
                <p className="m-0 regular-16">**128-2</p>
              </div>
            </div>

            <div className="col-sm-4 d-flex flex-column">
              <div className="row mb-4">
                <p className="mb-2 semibold-16">Data de Vencimento</p>
                <p className="m-0 regular-16">14/05/2021 (Agendamento)</p>
              </div>
              <div className="row mb-4">
                <p className="mb-2 semibold-16">Descrição</p>
                <p className="m-0 regular-16">Boleto - Design UIUX</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FORM BUTTONS */}
      <div className="p-3 p-md-4 d-flex flex-md-row justify-content-between justify-content-md-end align-items-center">
        <p
          onClick={() => setStep(1)}
          className="d-flex align-items-center text-decoration-none cursor-pointer m-0 me-md-4"
        >
          <FaChevronLeft className="text-black" />
          <span className="text-uppercase fw-bold text-black ms-2">Voltar</span>
        </p>
        <button
          onClick={emitirBoleto}
          disabled={loading}
          className="btn btn-primary"
        >
          {loading ? "Emitindo..." : "Emitir boleto"}
        </button>
      </div>
    </div>
  );
}
