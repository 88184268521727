import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import Input from "../../../../components/Input";
import { Loading } from "../../../../components/Loading";
import PessoaNatureza from "../../../../enums/pessoa-natureza";
import PropostaDadosEmpresaArgs from "../../../../interfaces/args/proposta-dados-empresa.args";
import PropostaArgs from "../../../../interfaces/args/proposta.args";
import ServiceResult from "../../../../interfaces/service-result";
import api from "../../../../services/api-client";
import apiErrorHandler from "../../../../services/api-error-handler";
import { useCadastroPJStore } from "../../../../stores/cadastro-pj-store";
import somenteNumeros from "../../../../utils/somente-numeros";

export const DadosEmpresa = () => {
  const { dadosEmpresa, setDadosEmpresa, setStep } = useCadastroPJStore();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<PropostaDadosEmpresaArgs>({
    values: dadosEmpresa,
  });

  async function onSubmit(data: PropostaDadosEmpresaArgs): Promise<void> {
    setLoading(true);

    const propostaId: string = localStorage.getItem("propostaId") ?? "";

    let args: PropostaArgs = {
      dadosPessoais: null,
      dadosEmpresa: {
        cnae: data.cnae,
        cnpj: somenteNumeros(data.cnpj),
        dataAbertura: moment(data.dataAbertura, "DD/MM/YYYY").format(
          "YYYY-MM-DD",
        ),
        inscricaoEstadual: data.inscricaoEstadual,
        razaoSocial: data.razaoSocial,
        tipoEmpresa: data.tipoEmpresa,
      },
      natureza: PessoaNatureza.Juridica,
      socios: [],
    };

    api
      .putForm<ServiceResult<string>>(`/propostas/${propostaId}`, {
        jsonData: JSON.stringify(args),
      })
      .then(() => {
        setDadosEmpresa(data);
        setStep("adicione-socios");
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-lg-5 mb-5 pb-4">
        <div className="row m-3">
          <p className="h1 mb-4">Insira os dados da empresa</p>
          <p className="h2 mb-3">Dados empresariais</p>
        </div>

        <div className="row m-3">
          <div className="col-lg-12 mb-3">
            <Input
              control={control}
              errors={errors}
              validation={{ required: "Tipo de empresa é obrigatório" }}
              label="Tipo de empresa"
              name="tipoEmpresa"
              placeholder="Tipo de empresa"
            />
          </div>

          <div className="col-lg-12 mb-3">
            <Input
              control={control}
              errors={errors}
              validation={{ required: "CNAE é obrigatório" }}
              label="CNAE"
              name="cnae"
              placeholder="Atividade"
            />
          </div>

          <div className="col-lg-12 mb-3">
            <Input
              control={control}
              errors={errors}
              validation={{ required: "Razão social é obrigatória" }}
              label="Razão social"
              name="razaoSocial"
              placeholder="Razão social"
            />
          </div>

          <div className="col-lg-6 mb-3">
            <Input
              control={control}
              errors={errors}
              validation={{ required: "CNPJ é obrigatório" }}
              mask="99.999.999/9999-99"
              maskChar="X"
              label="CNPJ"
              name="cnpj"
              placeholder="Insira seu CNPJ"
            />
          </div>

          <div className="col-lg-6 mb-3">
            <Input
              control={control}
              errors={errors}
              validation={{ required: "Data de abertura é obrigatória" }}
              mask="99/99/9999"
              maskChar="X"
              label="Data de abertura"
              name="dataAbertura"
              placeholder="XX/XX/XXXX"
            />
          </div>

          <div className="col-lg-12 mb-3">
            <Input
              control={control}
              errors={errors}
              validation={{}}
              label="Inscrição estadual"
              name="inscricaoEstadual"
              placeholder="Inscrição estadual (se não isento)"
            />
          </div>
        </div>
      </div>

      {/* WEB */}
      <div
        className={
          "position-fixed bottom-0 bg-white w-50 d-none d-lg-flex justify-content-between align-items-center p-3"
        }
        style={{ borderTop: "1px solid #E5E5E5" }}
      >
        <button
          className="btn d-flex align-items-center"
          onClick={() => setStep("dados-pessoais")}
        >
          <FaChevronLeft className="me-2" />
          <p className="semibold-14 m-0 text-uppercase">Voltar</p>
        </button>
        <button type="submit" disabled={loading} className="btn btn-primary">
          {loading ? "Carregando..." : "Avançar"}
        </button>
      </div>

      {/* MOBILE */}
      <div
        className={
          "position-fixed bottom-0 bg-white w-100 d-flex d-lg-none justify-content-between align-items-center p-3"
        }
        style={{ borderTop: "1px solid #E5E5E5" }}
      >
        <button
          className="btn d-flex align-items-center"
          onClick={() => setStep("dados-pessoais")}
        >
          <FaChevronLeft className="me-2" />
          <p className="semibold-14 m-0 text-uppercase">Voltar</p>
        </button>
        <button type="submit" disabled={loading} className="btn btn-primary">
          {loading ? "Carregando..." : "Avançar"}
        </button>
      </div>
    </form>
  );
};
