import { create } from "zustand";
import PropostaDadosPessoaisArgs from "../interfaces/args/proposta-dados-pessoais.args";

type CadastroPFStore = {
  step: "dados-pessoais" | "documentos";
  setStep: (step: "dados-pessoais" | "documentos") => void;
  dadosPessoais: PropostaDadosPessoaisArgs;
  setDadosPessoais: (args: PropostaDadosPessoaisArgs) => void;

  // aux
  fotoDocumentoFile: File | null;
  selfieDocumentoFile: File | null;
};

export const useCadastroPFStore = create<CadastroPFStore>()((set) => ({
  step: "dados-pessoais",
  setStep: (step) => {
    set((state) => {
      return {
        ...state,
        step,
      };
    });
  },
  dadosPessoais: {} as PropostaDadosPessoaisArgs,
  setDadosPessoais: (args: PropostaDadosPessoaisArgs) => {
    set((state) => {
      return {
        ...state,
        dadosPessoais: args,
      };
    });
  },
  fotoDocumentoFile: null,
  selfieDocumentoFile: null,
}));
