import ChavePixTipo from "../enums/chave-pix-tipo";

export default function convertEnumChavePixTipoToString(
  tipo: ChavePixTipo,
): string {
  switch (tipo) {
    case ChavePixTipo.CPF:
      return "CPF";
    case ChavePixTipo.CNPJ:
      return "CNPJ";
    case ChavePixTipo.Email:
      return "E-mail";
    case ChavePixTipo.Telefone:
      return "Celular";
    case ChavePixTipo.EVP:
      return "Aleatória";
    default:
      return "Tipo de chave não reconhecida";
  }
}
