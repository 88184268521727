import { FaCircleInfo } from "react-icons/fa6";

interface Props {
  text: string;
}

export const Info = ({ text }: Props) => {
  return (
    <div
      className="d-flex align-items-center p-3"
      style={{
        backgroundColor: "#3869E31A",
        border: "1px solid #3869E3",
        borderRadius: 4,
      }}
    >
      <FaCircleInfo
        className="d-none d-sm-block"
        style={{ fontSize: "1.5rem", color: "#3869E3" }}
      />
      <p className="m-0 ps-2 regular-14" style={{ whiteSpace: "pre-line" }}>
        {text}
      </p>
    </div>
  );
};
