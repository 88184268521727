import EmptyModal from "../../components/Modals/empty-modal";
import AuthLayout from "../../components/Layouts/auth";
import { Info } from "../../components/TextCards/info";
import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import api from "../../services/api-client";
import somenteNumeros from "../../utils/somente-numeros";
import ServiceResult from "../../interfaces/service-result";
import apiErrorHandler from "../../services/api-error-handler";
import { useState } from "react";
import ConfirmSMS from "../../components/confirm-sms";
import AuthModel from "../../interfaces/models/auth.model";
import { useGetDadosConta } from "../../hooks/useGetDadosConta";

interface FormValues {
  cpf: string;
  senha: string;
}

export default function Login() {
  const navigate = useNavigate();
  const getDadosConta = useGetDadosConta();

  const [loading, setLoading] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const isModalOpen = searchParams.get("modal") === "open";

  function handleCloseModal() {
    setSearchParams((state) => {
      state.delete("modal");
      return state;
    });
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<FormValues>({
    values: {
      cpf: "",
      senha: "",
    },
  });

  async function onSubmit(data: FormValues): Promise<void> {
    setLoading(true);

    let formData = new FormData();
    formData.append("grant_type", "2fa");
    formData.append("user", somenteNumeros(data.cpf));
    formData.append("password", data.senha);

    api
      .postForm<ServiceResult>("/auth/token", formData)
      .then(() => {
        setConfirm(true);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  async function confirmLogin(code: string): Promise<void> {
    setLoading(true);

    let formData = new FormData();
    formData.append("grant_type", "2fa");
    formData.append("user", somenteNumeros(getValues("cpf")));
    formData.append("code", code);

    api
      .postForm<ServiceResult<AuthModel>>("/auth/token/check", formData)
      .then(({ data: result }) => {
        sessionStorage.setItem("auth", JSON.stringify(result.data));

        // deu certo o login? faz um request para obter os dados da conta em seguida,
        //para quando a tela for montanda já estiver com os dados do usuários na tela!!
        getDadosConta();

        setConfirm(false);
        navigate("/");
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  return (
    <>
      {confirm && (
        <ConfirmSMS
          handleSubmit={confirmLogin}
          backAction={() => setConfirm(false)}
        />
      )}

      {!confirm && (
        <AuthLayout page="login">
          <div className="row px-3 px-md-5 mx-xxl-5 d-flex justify-content-center align-items-center">
            <div className="my-5">
              <img
                src={process.env.REACT_APP_LOGO}
                alt="logo"
                style={{ maxWidth: "100%", height: 100 }}
              />
            </div>

            <div className="mb-4">
              <p className="m-0 h1">Fazer login</p>
            </div>

            <div className="mb-4" style={{ textAlign: "justify" }}>
              <Info text="Fique atento! Nunca vamos te ligar para pedir senhas. Nunca forneça sua senha ou qualquer outro código por telefone." />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-3">
                <Input
                  control={control}
                  errors={errors}
                  validation={{ required: "CPF é obrigatório" }}
                  mask="999.999.999-99"
                  maskChar="X"
                  name="cpf"
                  label="CPF"
                  placeholder="Insira seu CPF"
                />
              </div>

              <div className="row mb-2">
                <Input
                  control={control}
                  errors={errors}
                  validation={{ required: "Senha é obrigatória" }}
                  name="senha"
                  type="password"
                  label="Senha"
                  placeholder="Insira sua senha"
                />
              </div>

              <div className="row mb-5">
                <div className="d-flex justify-content-end">
                  <Link
                    to="/recuperar-senha"
                    className="link text-black medium-16"
                  >
                    Esqueci minha Senha
                  </Link>
                </div>
              </div>

              <div className="d-grid gap-2 pb-3">
                <button
                  className="w-100 btn btn-primary btn-lg"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Carregando..." : "Acessar"}
                </button>
              </div>

              <div className="row mb-5">
                <div
                  className="d-flex justify-content-center regular"
                  style={{ fontSize: 16 }}
                >
                  <p className="text-black regular-16 m-0 me-2">
                    Ainda não tem conta?
                  </p>
                  <p
                    className="text-black medium-16 link m-0"
                    onClick={() => setSearchParams({ modal: "open" })}
                  >
                    Abra agora
                  </p>
                </div>
              </div>
            </form>
          </div>

          {isModalOpen && (
            <EmptyModal
              isOpen={isModalOpen}
              close={handleCloseModal}
              title="Abertura de conta"
              overlayClose
            >
              <p className="text-black text-center medium-16 mb-4">
                Para quem é a nova conta?
              </p>

              <button
                type="button"
                className="w-100 btn btn-primary"
                onClick={() => navigate("/cadastro/pf")}
              >
                Para mim
              </button>

              <button
                type="button"
                className="w-100 btn btn-primary mt-3"
                onClick={() => navigate("/cadastro/pj")}
              >
                Para minha empresa
              </button>
            </EmptyModal>
          )}
        </AuthLayout>
      )}
    </>
  );
}
