import { create } from "zustand";

type CobrarPIXStore = {
  qrCode: string;
  setQrCode: (qrCode: string) => void;
};

export const useCobrarPIXStore = create<CobrarPIXStore>()((set) => ({
  qrCode: "",
  setQrCode: (qrCode: string) => set({ qrCode }),
}));
