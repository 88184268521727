import { Link } from "react-router-dom";
import { IconBoletos } from "../../Icons/boletos";
import { IconExtract } from "../../Icons/extract";
import { HomeIcon } from "../../Icons/home";
import { IconPagarConta } from "../../Icons/pagar-conta";
import { IconPix } from "../../Icons/pix";
import { IconTransfer } from "../../Icons/transfer";

const links = [
  {
    path: "/",
    icon: <HomeIcon />,
    label: "Home",
  },
  {
    path: "/extrato",
    icon: <IconExtract />,
    label: "Extrato",
  },
  {
    // path: "/transferencias",
    path: "/dev",
    icon: <IconTransfer />,
    label: "Transferências",
  },
  {
    path: "/pix",
    icon: <IconPix />,
    label: "Pix",
  },
  {
    path: "/pagar-conta",
    icon: <IconPagarConta />,
    label: "Pagar Conta",
  },
  {
    path: "/boletos",
    icon: <IconBoletos />,
    label: "Boletos",
  },
];

export const NavLinks = () => {
  return (
    <div>
      <div className="d-flex mt-3 p-0 flex-column">
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.path}
            className="text-decoration-none text-white w-100 d-flex align-items-center mb-3"
          >
            <div
              className="d-flex align-items-center justify-content-start"
              style={{ width: "40px" }}
            >
              {link.icon}
            </div>
            <span className="text-white medium-14">{link.label}</span>
          </Link>
        ))}

        {/*
        <Link
          to="/favorecidos"
          className="text-decoration-none text-white w-100 d-flex align-items-center mb-3"
        >
          <div
            className="d-flex align-items-center justify-content-start"
            style={{ width: "40px" }}
          >
            <IconFavorecidos />
          </div>
          <span className="text-white medium-14">Favorecidos</span>
        </Link>
        */}

        {/*
        <Link
          to="/autorizacoes"
          className="text-decoration-none text-white w-100 d-flex align-items-center mb-3"
        >
          <div
            className="d-flex align-items-center justify-content-start"
            style={{ width: "40px" }}
          >
            <IconAutorizacoes />
          </div>
          <span className="text-white medium-14">Autorizações</span>
        </Link>
        */}
      </div>
    </div>
  );
};
