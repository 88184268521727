import { FaChevronLeft } from "react-icons/fa6";

interface Props {
  children: React.ReactNode;
  backAction: () => void;
}

export default function CleanLayout({ children, backAction }: Props) {
  return (
    <div className="min-vh-100 bg-white">
      {/* WEB */}
      <div className="d-none d-lg-block position-relative py-5 px-3">
        <p
          onClick={backAction}
          className="cursor-pointer position-absolute top-50 translate-middle ms-5 text-black text-decoration-none d-flex align-items-center"
        >
          <FaChevronLeft className="me-2" />
          <span className="semibold-14 m-0 text-uppercase">Voltar</span>
        </p>

        <img
          className="position-absolute top-50 start-50 ms-0 ps-0 translate-middle"
          style={{ maxHeight: "50px" }}
          src={process.env.REACT_APP_LOGO}
          alt="logo"
        />
      </div>

      {/* MOBILE */}
      <div className="d-flex d-lg-none justify-content-between align-items-center py-4 px-2">
        <p
          onClick={backAction}
          className="cursor-pointer text-black text-decoration-none d-flex align-items-center"
        >
          <FaChevronLeft className="me-2" />
          <span className="semibold-14 m-0 text-uppercase">Voltar</span>
        </p>

        <img src={process.env.REACT_APP_LOGO_DARK} alt="logo" />
      </div>

      <div
        className="mt-5 d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "50vh" }}
      >
        {children}
      </div>
    </div>
  );
}
