import moment from "moment";
import { BiTransfer } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "../../../components/table-pagination";
import TransacaoTipo from "../../../enums/transacao-tipo";
import ExtratoListItemModel from "../../../interfaces/models/extrato-list-item.model";
import { convertCentsToCurrencyString } from "../../../utils/convert-cents-to-currency-string";

interface Props {
  numeroPaginas: number;
  transacoes: Array<ExtratoListItemModel>;
}

export const TableExtrato = ({ numeroPaginas, transacoes }: Props) => {
  const navigate = useNavigate();

  function irParaDetalhes(id: string, tipoTransacao: TransacaoTipo) {
    const tipo = tipoTransacao.toString().toUpperCase();

    if (tipo.startsWith("PIX")) {
      navigate(`/extrato/pix/${id}`);
    } else if (tipo.startsWith("BOLETO")) {
      navigate(`/extrato/boleto/${id}`);
    }
  }

  return (
    <div className="row mx-0">
      <div
        className="bg-white col-sm-12 p-0"
        style={{ border: "1px solid #e8e8e8", borderRadius: 4 }}
      >
        <table className="table m-0 text-black table-responsive-stack">
          <thead>
            <tr>
              <th scope="col" className="py-3 ps-3">
                Transação
              </th>
              <th scope="col" className="py-3">
                Data
              </th>
              <th scope="col" className="py-3">
                Valor
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {transacoes.map((t, index) => (
              <tr className="align-middle" key={index}>
                <td className="py-3 ps-3 col-sm-5">
                  <div className="d-flex align-items-center">
                    <div className="px-2">
                      <BiTransfer style={{ fontSize: "1.7rem" }} />
                    </div>
                    <div className="d-flex flex-column">
                      <p className="m-0 medium-14">{t.situacaoDetalhe}</p>
                      <p className="m-0 regular-14">
                        {t.transacaoBoletoPagadorNome}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="py-3">
                  <div className="d-flex flex-column">
                    <p className="m-0 medium-14">
                      {moment(t.data).add(-3, "hours").format("DD/MM/YYYY")}
                    </p>
                    <p className="m-0 regular-14">
                      {moment(t.data).add(-3, "hours").format("HH[h]mm[m]")}
                    </p>
                  </div>
                </td>
                <td className="py-3">
                  {t.tipo.toString().toUpperCase().includes("RECEBIMENTO") &&
                  !t.tipo.toString().toUpperCase().includes("TARIFA") ? (
                    <p className="m-0 medium-14" style={{ color: "#30C179" }}>
                      {`+ ${convertCentsToCurrencyString(t.valorCentavos)}`}
                    </p>
                  ) : (
                    <p className="m-0 medium-14" style={{ color: "#D32F2F" }}>
                      {`- ${convertCentsToCurrencyString(t.valorCentavos)}`}
                    </p>
                  )}
                </td>

                {/*
                <td>
                  {!t.tipo.toString().toUpperCase().includes("TARIFA") && (
                    <div className="d-flex justify-content-center">
                      <div className="dropdown" data-bs-toggle="dropdown">
                        <FaEllipsisVertical
                          className="cursor-pointer px-3 py-1"
                          style={{ fontSize: "2.4rem" }}
                        />
                      </div>
                      <ul className="dropdown-menu mt-2">
                        <li>
                          <button
                            onClick={() => irParaDetalhes(t.id, t.tipo)}
                            className="dropdown-item d-flex"
                          >
                            <div
                              className="d-flex justify-content-center align-items-center me-2"
                              style={{ width: 20 }}
                            >
                              <FaCircleInfo />
                            </div>
                            Detalhes da transação
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </td>
                */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <TablePagination numeroPaginas={numeroPaginas} />
    </div>
  );
};
